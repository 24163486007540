import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import queryString from 'query-string';
import get from 'lodash/get';
import toString from 'lodash/toString';
import isEmpty from 'lodash/isEmpty';

import { fetchSaga } from './fetchSaga';
import { setModel } from 'core/redux/slices/models';
import {
  appInitialize,
  appLogout,
  setDict,
  setLoading,
} from 'core/redux/slices/globals';
import { addNotification } from 'core/redux/slices/notifications';
import clearAuthStorage from 'utils/clearAuthStorage';
import { Widget } from 'constants/widget';
import { getApi } from 'core/api';
import { NotificationType } from 'constants/enums/NotificationType';
import { ResponseStatus } from 'constants/ResponseStatus';
import { Path } from 'routes/Path';
import {
  isAuthorized,
  removeAuthParams,
  setAccessToken,
  setBackUrlToStorage,
  setClientIdToStorage,
} from 'utils/authUtils';
import UrlParams from 'constants/UrlParams';
import { UserType } from 'constants/enums/UserType';
import { getClientIdByUserType } from 'utils/storageUtils';

export function redirectToLogin(): void {
  clearAuthStorage();
  removeAuthParams(true);

  if (process.env.REACT_APP_LOGIN_URL) {
    window.location.replace(process.env.REACT_APP_LOGIN_URL);
  } else {
    throw new Error('The process.env does not contain a REACT_APP_LOGIN_URL');
  }
}

export function redirectToNotFoundPage(): void {
  window.location.replace(Path.Error);
}

function* initialize() {
  const query = queryString.parse(window.location.search, {
    decode: true,
    parseNumbers: true,
  });

  if (query) {
    const sessionToken = toString(query[UrlParams.AUTH_TOKEN]);
    if (!isEmpty(sessionToken)) {
      setAccessToken(sessionToken, true);
      const userType = (query[UrlParams.AUTH_USER_TYPE] ??
        UserType.PARTNER) as UserType;
      const clientId = getClientIdByUserType(userType);
      if (clientId) {
        setClientIdToStorage(toString(clientId), true);
      }
      const backUrl = query[UrlParams.BACK_URL];
      if (!isEmpty(backUrl)) {
        setBackUrlToStorage(toString(backUrl), true);
      }
    }
  }

  if (!isAuthorized()) {
    redirectToLogin();
  }

  try {
    yield put(setLoading(true));
    const { data: user, meta } = yield call(
      fetchSaga,
      getApi().Profile.GET_PROFILE,
      {},
    );
    const dict = get(meta, 'dict', null);
    yield put(setModel({ modelId: Widget.User, datasource: user }));

    if (dict) {
      yield put(setDict(dict));
    }
  } catch (err: any) {
    if (err.status === ResponseStatus.Unauthorized) {
      redirectToLogin();
    }

    console.error(err);
  } finally {
    yield put(setLoading(false));
  }
}

function* logout() {
  try {
    const Api = getApi();

    yield call(fetchSaga, Api.Profile.LOGOUT, {});
    redirectToLogin();
  } catch (err: any) {
    yield put(
      addNotification({
        id: 'logout-error',
        message: err.toString(),
        type: NotificationType.DANGER,
      }),
    );
  }
}

export default [
  takeEvery(appInitialize.type, initialize),
  takeLatest(appLogout.type, logout),
];
