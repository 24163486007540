export default {
  offers: 'Офферы',
  offer: 'Оффер',
  new_offer: 'Новый оффер',
  edit_offer: 'Редактировать оффер',
  edit: 'Редактировать',
  general_data: 'Общие данные',
  offer_name: 'Название оффера',
  top: 'ТОП',
  top_help:
    'Добавляет лейбл TOP на аватар оффера. Не влияет на сортировку и порядок отображения для партнера.',
  status_name: 'Название статуса',
  product: 'Продукт',
  product_hint: 'Продукт, для которого создаётся этот оффер',
  unlimited: 'Безлимитный',
  use_hold_period: 'Период холда',
  percent: 'Процент',
  fixed: 'Фиксированная сумма',
  days: 'дни',
  to_days: 'дней',
  offer_successfully_created: 'Оффер успешно создан',
  offer_successfully_updated: 'Оффер успешно изменен',
  select_product_from_the_list: 'Выберите ранее созданный продукт из списка',
  set_limit_of_each_condition_performance_per_day_the_minimum_value_is_10_the_maximum_values_is_1_000_000_you_can_also_set_unlimited_value_by_checkbox:
    'Укажите лимит на выполнение каждого условия в сутки. Минимальное значение 10, максимальное значение 1 000 000. Также можно выбрать неограниченный лимит.',
  set_day_count_to_hold_period_the_minimum_values_is_0_days_the_maximum_values_is_60_days:
    'Укажите количество дней для периода холда. Минимальное значение 0 дней, максимальное значение 60 дней.',
  no_offers: 'Нет офферов',
  no_active_offers: 'Нет активных офферов',
  no_active_goals: 'Нет активных целей.',
  no_goals_groups: 'Нет групп целей',
  please_create_goal: 'Пожалуйста, создайте цель.',
  table_name: 'Оффер',
  table_product: 'Продукт',
  table_category: 'Категория',
  table_advertiser: 'Рекламодатель',
  table_status: 'Статус',
  table_privacy: 'Приватность',
  table_hold: 'Холд',
  table_creation_date: 'Дата создания',
  status_active: 'Активный',
  status_inactive: 'Неактивный',
  event: 'Событие',
  range: 'Диапазон',
  model: 'Модель',
  values: 'Значения',
  name_of_goal: 'Название цели',
  id_of_goal: 'Идентификатор цели',
  id: 'ID',
  from_3_to_30_Latin_letters_and_symbols:
    'От 3 до 30 латинских букв, цифр и символов',
  condition: 'Условие',
  no_goals: 'Нет целей',
  no_formulas: 'Нет формул',
  conditions: 'Условия',
  individual: 'Индивидуальное',
  individual_conditions: 'Индивидуальные условия',
  range_help:
    'Укажите диапазон значений для подсчета выполненных целей в формате [X, Y]. X и Y должны быть положительными числами. Также можно использовать знак @, который обозначает бесконечность. Например [10,@], [5,15], @',
  value_help:
    'Укажите диапазон значений в формате [X,Y], определяющий сумму совершаемого события для данной цели. X и Y должны быть числами. Также можно использовать знак @, который обозначает бесконечность. Например, [10,@], [5,15], @. Также, Х может быть равно -@, например [-@, 100], [-@, @].',
  model_help:
    'Укажите модель подсчета:\n' +
    'Sum - значения из постбеков продукта будут суммироваться и сумма будет сравниваться с диапазоном в поле Значения\n' +
    'Between - значение из постбека продукта будет сравниваться с диапазоном в поле Значения',
  basic: 'Базовые',
  goals: 'Цели',
  goal: 'Цель',
  conversion_statuses: 'Статусы конверсий',
  benefit: 'Вознаграждение',
  countries: 'Страны',
  cities: 'Города',
  operating_systems: 'Операционные системы',
  operating_systems_short: 'ОС',
  device_types: 'Типы устройства',
  device_types_short: 'Устройство',
  type: 'Тип',
  benefit_type: 'Тип выплаты',
  value: 'Значение',
  cannot_delete_active_offer: 'Нельзя удалить активный оффер',
  qualification_mode: 'Режим Квалификация',
  qualification_mode_help:
    'Квалификация - это конверсия, которая создается только при выполнении всех целей указанных в данном блоке.',
  qualification_type_help:
    'Конверсия создается только при выполнении всех целей указанных в данном блоке.',
  add_first_condition: 'Добавьте первое условие для этого оффера',
  add_first_individual_condition:
    'Добавьте первое индивидуальное условие для этого оффера',
  frequency_of_payments: 'Частота выплат',
  add_goal: 'Добавить цель',
  add_goals: 'Добавить цели',
  edit_goal: 'Редактировать цель',
  add_landing: 'Добавить лендинг',
  offer_tracking_target_link: 'Таргет-ссылка',
  offer_tracking_target_link_hint:
    '{click_id} - уникальный идентификатор клика;\n{partner_id} - ID партнера;\n{offer_id} - ID оффера;\n{creative_id} - идентификатор промо-материала;\n{sub_id1..10} - SubId #1 - SubId #10.',
  offer_trafficback_url: 'Trafficback URL',
  offer_trafficback_url_hint:
    'Если трафик не соответствует параметрам, он будет перенаправлен на данный URL. В ссылке могут быть указаны макросы:\n\n{click_id} - уникальный идентификатор клика;\n{partner_id} - ID партнера;\n{sub_id1} - sub_id #1, можно добавить до 10 sub_id;\n{offer_id} - ID оффера;\n{creative_id} - идентификатор промо-материала.',
  offer_tracking_tracking_domain: 'Домен трекинга',
  condition_successfully_created: 'Условие успешно создано',
  condition_successfully_updated: 'Условие успешно обновлено',
  conditions_successfully_updated: 'Условия успешно обновлены',
  condition_successfully_deleted: 'Условие успешно удалено',
  an_error_occurred_while_deleting_a_condition:
    'Произошла ошибка при удалении условия',
  show_all_partners: 'Показать всех {{count}} партнёров',
  something_went_wrong: 'Что-то пошло нет так',
  form_product: 'Продукт',
  form_advertiser: 'Рекламодатель',
  form_category: 'Категория',
  form_type: 'Тип',
  form_day_limit: 'Дневной лимит',
  form_hold_period: 'Период холда',
  form_postclick: 'Постклик',
  form_postclick_from: 'Считать постклик от',
  form_target_link: 'Target-ссылка',
  my_referral_link: 'Моя реферальная ссылка',
  partners: 'Партнеры',
  partner: 'Партнер',
  add_condition: 'Добавить условие',
  add_individual_conditions: 'Добавить индивидуальное условие',
  edit_conditions: 'Редактировать условия',
  edit_individual_conditions: 'Редактировать индивидуальные условия',
  day_limit: 'Дневной лимит',
  no_limit: 'Нет лимита',
  no_conditions_created: 'Нет созданных условий',
  view_form_table_countries: 'Страны',
  view_form_table_day_limit: 'Дневной лимит',
  view_form_table_hold: 'Холд',
  view_form_table_benefit: 'Партнёру',
  view_form_table_goals: 'Цели',
  view_form_table_name_id: 'Название / ID',
  view_form_table_event: 'Событие',
  view_form_table_range: 'Диапазон',
  view_form_table_model: 'Модель',
  view_form_table_value: 'Значение',
  choose_partners: 'Выберите партнеров...',
  get_referral_link: 'Генератор ссылок',
  hold_period: 'Период холда',
  postbacks: 'Постбеки',
  rule: 'Правило',
  frequency: 'Частота',
  referral_link: 'Реферальная ссылка',
  copy_link: 'Скопировать ссылку',
  get_qr_code: 'Получить QR-код',
  save_as_png: 'Сохранить как PNG',
  save_as_svg: 'Сохранить как SVG',
  landing: 'Лендинг',
  sub_id: 'Sub id',
  add_sub_id: 'Добавить Sub id',
  maximum_10_sub_id: 'Максимум 10 Sub_id',
  from_3_to_30_characters: 'От 3 до 30 символов',
  from_1_to_60_characters: 'От 1 до 60 символов',
  from_3_to_60_characters: 'От 3 до 60 символов',
  are_you_sure_you_want_to_delete_the_condition:
    'Вы уверены, что хотите удалить условие?',
  are_you_sure_you_want_to_delete_the_goal:
    'Вы уверены, что хотите удалить цель?',
  are_you_sure_you_want_to_delete_the_formula:
    'Вы уверены, что хотите удалить формулу?',
  goal_successfully_deleted: 'Цель успешно удалена',
  goal_successfully_created: 'Цель успешно создана',
  goal_successfully_updated: 'Цель успешно обновлена',
  unable_delete_formula:
    'Невозможно удалить формулу, так как она используется в условиях оффера.',
  formula_successfully_deleted: 'Формула успешно удалена',
  formula_successfully_updated: 'Формула успешно обновлена',
  enabled_offer_tooltip: 'Включить оффер.',
  disable_offer_tooltip:
    'Отключить оффер. При отключении оффера он становится недоступным для партнера.',
  more_countries: 'стран',
  more_partners: 'партнеров',
  tracking: 'Трекинг',
  'Errors were made in creating the condition.':
    'Были допущены ошибки при создании условия.',
  'Please correct them.': 'Пожалуйста, исправьте их.',
  hidden: 'Скрыто',
  hidden_from: 'Скрыто от',
  hide_condition_from_partner: 'От партнера',
  hide_condition_from_advertiser: 'От рекламодателя',
  hide_condition_partner: 'Партнера',
  hide_condition_advertiser: 'Рекламодателя',
  show_to_partner: 'Показать партнёру',
  show_to_advertiser: 'Показать рекламодателю',
  show_individual_tag: 'Отображать метку Индивидуальное',
  hide_condition_in_offer:
    'Скрыть условие от партнера или рекламодателя. При скрытии условия оно не будет отображаться в списке условий оффера у партнера или рекламодателя. Также по скрытому условию у партнера или рекламодателя не будут отображаться конверсии и квалификации в статистике.',
  do_you_want_to_delete: 'Вы хотите удалить',
  condition_is_hidden: 'условие\nскрыто',
  from_advertiser: 'От рекламодателя',
  to_partner: 'Партнеру',
  payout_value_help:
    'Cумма, которую вы получаете от рекламодателя за квалификацию. Если Вид выплаты "Процент", то указывается процент от значения квалификации.',
  reward_value_help:
    'Сумма, которую вы платите партнеру. Если Вид выплаты "Процент", то указывается процент от значения квалификации. По умолчанию выплата равна нулю.',
  public: 'Публичный',
  private: 'Приватный',
  premoderate: 'Пре-модерация',
  available: 'Доступен',
  hide_unavailable: 'Скрыть недоступные',
  tags: 'Теги',
  partners_for_private_offer: 'Парнеры для приватного оффера',
  choosed: 'Выбрано',
  no_partners_selected: 'Нет выбранных партнеров',
  partners_successfully_saved: 'Партнеры успешно сохранены',
  all_countries: 'Все страны',
  postback_successfully_updated: 'Постбек успешно обновлен',
  add_postback: 'Добавить постбек',
  edit_postback: 'Редактировать постбек',
  postback_URL: 'URL постбека',
  max_1024_symbols: 'Максимум 1024 символа',
  conversion: 'Конверсия',
  qualification: 'Квалификация',
  conversion_name: 'Название конверсии',
  the_following_data_will_be_sent: 'Следующие данные могут быть отправлены:',
  postback_url_help_conversion:
    '{status} - статус конверсии;\n{goal} - ключ цели, по которой выполнилась конверсия;\n{revenue} - сумма вознаграждения;\n{currency} - валюта выплаты партнеру (из конверсии);\n{promocode} - промокод;\n{country} - страна;\n{sub_id1..sub_id10} - SubId #1 - SubId #10 (подставляются sub_id из клика);\n{id} - идентификатор конверсии;',
  revshare_postback_url_help: `{id} - ID конверсии;
{revenue} - сумма выплат партнеру по ревшаре;
{currency} - валюта выплаты партнеру;
{sub_id1..sub_id10} - SubId #1 - SubId #10 (подставляются sub_id из клика);`,
  postback_url_help_value: `{value} - значение конверсии;
{value_currency} - валюта значения конверсии;`,
  revshare_postback_url_help_value: `{value} - результат вычисления формулы (доход);
{value_currency} - валюта из условия с формулой;`,
  global_postback_url_help: `{status} - статус конверсии;
{goal} - ключ цели;
{offer_id} - ID оффера;
{revenue} - величина выплаты партнеру;
{currency} - валюта выплаты партнеру;
{sub_id1..sub_id10} - SubId #1 - SubId #10 (подставляются из клика);
{id} - ID конверсии;
{country} - страна;
{landing_id} - ID лендинга;
{click_date} - дата и время клика в формате YYYY.MM.DD HH:mm:ss;
{conversion_date} - дата и время конверсии в формате YYYY.MM.DD HH:mm:ss;
{ip} - IP-адрес клика;
{promocode} - Промокод;`,
  global_admin_postback_url_help: `{status} - статус конверсииj
{goal} - ключ группы целей;
{advertiser_id} - ID рекламодателя;
{partner_id} - ID партнера;
{offer_id} - ID оффера;
{revenue} - величина выплаты партнеру;
{currency} - валюта выплаты партнеру;
{value} - сумма конверсии;
{value_currency} - валюта конверсии;
{sub_id1..sub_id10} - SubId #1 - SubId #10 (подставляются из клика);
{id} - ID конверсии;
{country} - страна;
{landing_id} - ID лендинга;
{click_date} - дата и время клика в формате YYYY.MM.DD HH:mm:ss;
{conversion_date} - дата и время конверсии в формате YYYY.MM.DD HH:mm:ss;
{ip} - IP-адрес клика;
{click_id} - ID клика;
{tid} - ID транзакции;
{promocode} - Промокод;
{useragent} - значение UserAgent;`,
  do_you_really_want_to_delete_postback:
    'Вы действительно хотите удалить постбек?',
  an_error_occurred_while_deleting: 'При удалении произошла ошибка',
  postback_successfully_created: 'Постбек успешно создан',
  postback_successfully_deleted: 'Постбек успешно удален',
  no_postbacks: 'Нет постбеков',
  access_request_sent: 'Запрос отправлен',
  access_request_denied: 'Запрос отклонен',
  request_access: 'Запросить доступ',
  request_again: 'Запросить снова',
  requests: 'Запросы',
  save: 'Сохранить',
  create: 'Создать',
  description: 'Описание',
  add_partners: 'Добавить партнеров',
  exclude_partners: 'Исключить партнёров',
  exclude_partners_tooltip:
    'Администратор может исключить партнёра из оффера, запретив ему доступ. После этого оффер больше не будет отображаться в списке доступных офферов у партнёра.',
  excluded_partners: 'Исключённые партнёры',
  unique_goals: 'Уникальные цели',
  poster: 'Постер',
  special_payout: 'Специальная выплата',
  special_payouts: 'Специальные выплаты',
  payouts: 'Выплаты',
  revshare: 'Ревшара',
  revshare_description:
    'Процентная выплата от чистой прибыли продукта (NGR и др.)',
  goal_description: 'CPA или процентная выплата',
  cpm: 'CPM',
  cpm_description: 'Фиксированная оплата за 1000 действий пользователя',
  default_value: 'Значение по умолчанию',
  overridden: 'Переопределено',
  goals_not_found: 'Нет созданных целей.',
  create_goal_in_product_form: 'Вы можете добавить цель на',
  product_page: 'странице продукта',
  formulas_not_found: 'Нет созданных формул.',
  add_new_goal: 'Добавить новую цель',
  create_formula_in_product_form: 'Вы можете добавить формулу на',
  can_not_edit_revshare_condition:
    'Нельзя изменить условие, так как функционал по работе с ревшарой отключен',
  cost_per_action: 'CPA',
  per_conversion: 'За конверсию',
  select_type: 'Выберите тип',
  convert_to_qualification: 'Конвертировать в квалификацию',
  convert_to_goal: 'Конвертировать в простую цель',
  offer_form_special_payouts_one: '{{count}} специальная выплата',
  offer_form_special_payouts_few: '{{count}} специальные выплаты',
  offer_form_special_payouts_many: '{{count}} специальных выплат',
  special_payout_is_empty: 'Пустые параметры sub',
  payout: 'Выплата',
  payout_number: 'Специальная выплата {{number}}',
  payout_from_advertiser: 'Выплата от рекламодателя',
  payout_from_partner: 'Выплата от партнера',
  add_payout: 'Специальная выплата',
  the_amount_of_payments_can_be_specified_as_a_fractional_number:
    'Сумму выплат можно указать дробным числом.',
  name: 'Название',
  formula: 'Формула',
  formulas: 'Формулы',
  no_conditions_yet: 'Здесь пока нет условий.',
  add_new_condition: 'Добавить новое',
  privacy_level: 'Уровень приватности',
  request_type: 'Тип запроса',
  special_settings: 'Расширенные настройки',
  back_to_offer: 'Назад к офферу',
  no_active_conditions: 'Нет активных условий.',
  please_create_condition:
    'Пожалуйста, добавьте обычное или индивидуальное условие.',
  payment_currency: 'Валюта платежа',
  payout_currency_from_advertiser: 'Валюта выплаты от рекламодателя',
  partner_payout_currency: 'Валюта выплаты партнеру',
  goal_by_event: 'Цель по событию продукта',
  goal_by_event_help:
    'Добавить к цели событие, чтобы партнерская сеть обрабатывала действия пользователя самостоятельно',
  goal_by_event_cpm_help:
    'Если цель участвует в условии с моделью CPM, то для нее нельзя указать событие.',
  field_macros_goal: 'Значение будет присвоено макросу {goal}',
  field_macros_status: 'Значение будет присвоено макросу {status}',
  postclick_help:
    'Постклик - это время (в днях), в течение которого пользователь считается привязанным к партнеру.',
  group: 'Группа',
  request_date: 'Дата заявки',
  requests_for_access: 'Заявки на доступ',
  no_access_requests: 'Нет заявок на доступ к офферу',
  offer_statistics: 'Статистика оффера',
  duplicate_label: 'Дублировать оффер',
  duplicate_form_title: 'Дублировать оффер',
  duplicate_form_name_field: 'Название нового оффера',
  duplicate_form_submit: 'Дублировать',
  to_duplicate: 'Копировать',
  s2s_postback: 'S2S-постбек',
  note: 'Внутренняя заметка',
  card_note: 'Заметка',
  limit: 'Лимит',
  limit_conversions: 'Лимит конверсий',
  limit_period: 'Период',
  limit_period_1: 'В день',
  limit_period_2: 'В неделю',
  limit_period_3: 'В месяц',
  limit_period_4: 'Всего',
  limit_period_label_1: '1 день',
  limit_period_label_2: '1 неделя',
  limit_period_label_3: '1 месяц',
  limit_period_label_4: 'Всего',
  limit_action: 'Действие при превышении',
  limit_action_1: 'Отклонить конверсию',
  limit_action_2: 'Изменить выплату партнеру',
  limit_action_3: 'Изменить доход от рекламодателя',
  limit_action_4: 'Изменить выплату партнеру и доход от рекламодателя',
  partner_select_label: 'Для партнёра',
  excluded_partners_label: 'Исключить партнёров',
  excluded_partners_placeholder: 'Партнеры для исключения (ID)',
  need_to_add_partners: 'Нужно добавить партнёров',
  referral_link_tooltip: 'Выберите партнёра из списка ниже',
  referral_link_tooltip_offer: 'Выберите оффер из списка ниже',
  partner_placeholder: 'Введите id или Email для партнера',
  offer_placeholder: 'Введите id или название оффера',
  landing_placeholder: 'Название лендинга',
  goal_presets: 'Пресеты целей',
  trigger: 'Триггер',
  trigger_creating: 'При создании конверсии',
  trigger_status_change: 'При изменении статуса',
  trigger_payout_change: 'При изменении выплаты',
  is_use_in_app: 'Используется для приложений',
  is_use_in_app_hint:
    'Отметка, что данный оффер предназначен для пользователей приложения. При получении клика по такому офферу в клик будет добавлена соответствующая отметка.',
  conditions_search_label: 'ID Партнёра',
  conditions_search_label_mobile: 'Искать по ID партнёра',
  copy_offer_form_title: 'Скопировать оффер',
  copy_offer: 'Скопировать оффер в другой продукт',
  copy_offer_alert:
    'Не будут скопированы: Условия, лендинги, промоматериалы и постбеки партнеров',
  copy_form_submit: 'Скопировать',
  offer_partners_empty_title: 'Оффер не доступен ни одному партнёру',
  offer_partners_empty_text:
    'Открыть доступ нужным партнёрам можно в форме оффера',
  nco_type: 'Негативные остатки',
  nco_help:
    'NCO: Негативный Перенос - политика, при которой отрицательные балансы переносятся на следующий период, вычитая убытки из будущих доходов до достижения положительного баланса.',
  nnco_help:
    'NNCO: Политика Без Негативного Переноса означает обнуление отрицательных балансов в конце периода, что предотвращает перенос убытков на будущее.',
  nnco_enabled: 'Включено для',
  nnco_enabled_partner: 'Включено',
  nnco_enabled_for_all: 'всех',
};
