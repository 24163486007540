export default {
  no_data: 'No hay resultados de exportación',
  column_date: 'Fecha',
  column_type: 'Tipo',
  column_status: 'Estado',
  description_1st_line:
    'Normalmente se tarda entre 1 y 3 minutos en preparar un expediente',
  description_2nd_line_1st_part:
    'Si el estado del documento no ha cambiado después de 3 minutos,',
  description_2nd_line_2nd_part: 'actualizar la página',
  description_3rd_line: 'Los archivos finalizados se almacenan durante 1 día',
  type_conversions: 'Lista de conversión',
  type_common_statistic_snapshot: 'General estadisticas',
  type_invoices: 'Cuenta',
  type_partners: 'Socios',
  type_advertisers: 'Anunciantes',
  type_admins: 'Administradores',
  type_payouts: 'Pagos',
  type_promos: 'Materiales promocionales',
  type_offers: 'Ofertas',
  type_products: 'Productos',
  type_events: 'Lista de eventos',
  type_cohort_clicks: 'Análisis de cohortes por clics',
  type_cohort_events: 'Análisis de cohortes por evento',
  type_cohort_conversions: 'Análisis de cohortes por conversiones (objetivos)',
};
