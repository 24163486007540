export default {
  'search by id or name': 'Search by ID or name',
  search_by_id_or_email: 'Search by ID or email',
  switch_to_lang: 'Switch to {{lang}}',
  next: 'Next',
  active: 'Active',
  inactive: 'Inactive',
  registration_not_completed: 'Registration not completed',
  banned: 'Banned',
  item_selected: 'item selected',
  items_selected: 'items selected',
  items_more_selected: 'items selected',
  disabled: 'Disabled',
  moderation: 'Moderation',
  pending: 'Pending',
  rejected: 'Rejected',
  profile_disabled: 'Disabled',
  profile_rejected: 'Rejected',
  approved: 'Approved',
  canceled: 'Canceled',
  select_to_choice: 'Choice',
  select_to_search: 'Search',
  select_no_options: 'No options',
  today: 'Today',
  last: 'Last',
  '3_days': '3 days',
  '15_days': '15 days',
  week: 'Week',
  month: 'Month',
  apply: 'Apply',
  clear_all: 'Clear all',
  payed: 'Paid',
  hold: 'Hold',
  required: 'Required',
  optional: 'Optional',
  qualification: 'Qualification',
  conversion: 'Conversion',
  event: 'Event',
  show: 'Show',
  hide: 'Hide',
  public: 'Public',
  private: 'Private',
  premoderate: 'Pre-moderation',
  select_all_label: 'Select all',
  filter: 'Filter',
  any_option: 'Any',
  close_filter: 'Close filter',
  login_by_partner_part_1: 'Attention! You are using the partner ',
  login_by_partner_part_2: ' page on behalf of the administrator.',
  back_to_admin_page: 'Return to admin panel',
  allowed_formats_png_jpeg_jpg: 'Allowed formats: .png, .jpeg, .jpg',
  confirmed: 'Confirmed',
  select_all: 'Select all',
  unselect_all: 'Unselect all',
  group_actions: 'Group actions',
  or: 'or',
  click: 'Click',
  promo_code: 'Promo code',
  any_status: 'Any status',
  edited_by_a_moderator: 'Editing by moderator',
  goal_missing_in_condition: 'Goal missing in condition',
  over_limit: 'Over limit',
  over_postclick: 'Over postclick',
  payment_not_found: 'Payment not found',
  promocode_over_limit: 'Promo code over limit',
  promocode_is_expired: 'Promo code is expired',
  disconnected_from_offer: 'Disconnected from offer',
  rejected_by_product: 'Rejected by product',
  templates_max_count: 'Can be added only {{count}} times',
  template_value: 'Value',
  template_currency: 'Currency',
  template_currency_symbol: 'Currency symbol',
  template_default_currency: 'Value and code in default currency',
  template_default_currency_tooltip:
    'Can be added only 1 times. Available if Currency or Currency Symbol is set',
  in_en_language: 'In english',
  in_ru_language: 'In russian',
  in_es_language: 'In spanish',
  promo_code_assignment_type_all: 'All',
  promo_code_assignment_type_assigned: 'Assigned',
  promo_code_assignment_type_unassigned: 'Unassigned',
  promo_code_activity_type_all: 'All',
  promo_code_activity_type_active: 'Active',
  promo_code_activity_type_inactive: 'Inactive',
  selected_of: 'Selected {{selected}} of {{all}}',
  selected: 'Selected {{selected}}',
  showed: 'Showed',
  show_full: 'Show full',
  'nintendo proprietary': 'Nintendo',
  'nokia products': 'Nokia',
  other: 'Other',
  type: 'type',
  type_one: 'type',
  type_other: 'types',
  of: 'of',
  preparing: 'Preparing',
  done: 'Done',
  per_page: 'per page',
  enter_value: 'Enter a value',
  collapse: 'Collapse',
  more_number: '{{count}} more',
  all: 'All',
  new: 'New',
  drop_file: 'Drop file',
  upload: 'Upload',
  change: 'Change',
  wrong_file_type: 'Wrong file type',
  date_from: 'From',
  date_to: 'To',
  loading: 'Loading',
  load_more: 'Load more',
  input_more_symbols_to_search: 'Input {{count}} more symbols to search',
  input_one_more_symbols_to_search: 'Input one more symbol to search',
  paid: 'Paid',
  cpa: 'CPA',
  cpm: 'CPM',
  revshare: 'Revshare',
  awaiting_payment: 'Awaiting payment',
  for_the_product: 'For the product',
  for_the_offer: 'For the offer',
  fraud_level_not_analyzed: 'Not analyzed',
  fraud_level_ok: 'No risk',
  fraud_level_low: 'Low',
  fraud_level_mid: 'Medium',
  fraud_level_high: 'High',
  clicks: 'Clicks',
  referral_link: 'Referral link',
  promocodes: 'Promo codes',
  leads: 'Leads',
  offer_target_link: 'Offer target link',
  landing_target_link: 'Landing target link',
  expand: 'Expand',
  device_type_desktop: 'Desktop',
  device_type_mobile_phone: 'Mobile Phone',
  device_type_mobile_device: 'Mobile Device',
  device_type_tablet: 'Tablet',
  device_type_tv_device: 'TV Device',
  device_type_digital_camera: 'Digital Camera',
  device_type_car_entertainment_system: 'Car Entertainment System',
  device_type_ebook_reader: 'Ebook Reader',
  device_type_console: 'Console',
  show_user_contacts_filter: 'Show Filter by Contacts',
  hide_user_contacts_filter: 'Hide Filter by Contacts',
  pin: 'Pin',
  unpin: 'Unpin',
  exclude: 'Exclude',
  exclude_help: 'The selected items will be excluded',
  exclude_preview: 'All, excl.:',
  activity_check_text: 'The data has been updated',
  activity_check_refresh: 'Refresh',
  filters: 'Filters',
  search: 'Search',
  chosen: 'Chosen',
};
