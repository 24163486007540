export default {
  'search by id or name': 'Поиск по ID или названию',
  search_by_id_or_email: 'Поиск по ID или email',
  switch_to_lang: 'Переключиться на {{lang}}',
  next: 'Далее',
  active: 'Активен',
  inactive: 'Неактивен',
  registration_not_completed: 'Регистрация не завершена',
  banned: 'Забанен',
  item_selected: 'элемент выбран',
  items_selected: 'элемента выбрано',
  items_more_selected: 'элементов выбрано',
  disabled: 'Заблокировано',
  moderation: 'На модерации',
  pending: 'В ожидании',
  rejected: 'Отклоненная',
  profile_disabled: 'Заблокирован',
  profile_rejected: 'Отклонен',
  approved: 'Подтвержденная',
  canceled: 'Отменена',
  select_to_choice: 'Выбрать',
  select_to_search: 'Искать',
  select_no_options: 'Нет доступных вариантов',
  today: 'Сегодня',
  last: 'Последние',
  '3_days': '3 дня',
  '15_days': '15 дней',
  week: 'Неделя',
  month: 'Месяц',
  apply: 'Применить',
  clear_all: 'Очистить',
  payed: 'Выплачена',
  hold: 'Холд',
  required: 'Обязательный',
  optional: 'Необязательный',
  qualification: 'Квалификация',
  conversion: 'Конверсия',
  event: 'Событие',
  show: 'Показать',
  hide: 'Скрыть',
  public: 'Публичный',
  private: 'Приватный',
  premoderate: 'Пре-модерация',
  select_all_label: 'Выбрать всё',
  filter: 'Фильтр',
  any_option: 'Любой',
  close_filter: 'Закрыть фильтр',
  login_by_partner_part_1: 'Внимание! Вы используете страницу партнера ',
  login_by_partner_part_2: ' от лица администратора.',
  back_to_admin_page: 'Вернуться в админскую панель',
  allowed_formats_png_jpeg_jpg: 'Разрешенные форматы: .png, .jpeg, .jpg',
  confirmed: 'Подтверждена',
  select_all: 'Выбрать все',
  unselect_all: 'Снять все',
  group_actions: 'Групповые действия',
  or: 'или',
  click: 'Клик',
  promo_code: 'Промокод',
  any_status: 'Любой статус',
  edited_by_a_moderator: 'Редактирование модератором',
  goal_missing_in_condition: 'Цель отсутствует в условии',
  over_limit: 'Превышен лимит',
  over_postclick: 'Превышен постклик',
  payment_not_found: 'Выплата не найдена',
  promocode_over_limit: 'Превышен лимит промокодов',
  promocode_is_expired: 'Промокод истек',
  disconnected_from_offer: 'Отключен от оффера',
  rejected_by_product: 'Отклонено продуктом',
  templates_max_count: 'Можно добавить только {{count}} раз',
  template_value: 'Значение',
  template_currency: 'Валюта',
  template_currency_symbol: 'Знак валюты',
  template_default_currency: 'Значение и код в валюте по умолчанию',
  template_default_currency_tooltip:
    'Можно добавить 1 раз. Доступно, если указана Валюта или Знак валюты',
  in_en_language: 'На английском',
  in_ru_language: 'На русском',
  in_es_language: 'На испанском',
  promo_code_assignment_type_all: 'Все',
  promo_code_assignment_type_assigned: 'Привязанные',
  promo_code_assignment_type_unassigned: 'Непривязанные',
  promo_code_activity_type_all: 'Все',
  promo_code_activity_type_active: 'Активные',
  promo_code_activity_type_inactive: 'Неактивные',
  selected_of: 'Выбрано {{selected}} из {{all}}',
  selected: 'Выбрано {{selected}}',
  showed: 'Показано',
  show_full: 'Показать полностью',
  'nintendo proprietary': 'Nintendo',
  'nokia products': 'Nokia',
  other: 'Остальное',
  type: 'тип',
  type_one: 'тип',
  type_few: 'типа',
  type_many: 'типов',
  of: 'из',
  preparing: 'Выполняется',
  done: 'Завершено',
  per_page: 'на странице',
  enter_value: 'Введите значение',
  collapse: 'Cжать',
  more_number: 'ещё {{count}}',
  all: 'Все',
  new: 'Новый',
  drop_file: 'Перетащите файл',
  upload: 'Загрузить',
  change: 'Изменить',
  wrong_file_type: 'Неправильный тип файла',
  date_from: 'С',
  date_to: 'По',
  loading: 'Загрузка',
  load_more: 'Загрузить ещё',
  input_more_symbols_to_search: 'Введите еще {{count}} символа для поиска',
  input_one_more_symbols_to_search: 'Введите еще один символ для поиска',
  paid: 'Выплачено',
  cpa: 'CPA',
  cpm: 'CPM',
  revshare: 'Ревшара',
  awaiting_payment: 'Ожидает оплаты',
  for_the_product: 'Для продукта',
  for_the_offer: 'Для оффера',
  fraud_level_not_analyzed: 'Не проанализировано',
  fraud_level_ok: 'Без риска',
  fraud_level_low: 'Низкий',
  fraud_level_mid: 'Средний',
  fraud_level_high: 'Высокий',
  clicks: 'Клики',
  referral_link: 'Реферальная ссылка',
  promocodes: 'Промокоды',
  leads: 'Внешние лиды',
  offer_target_link: 'Прямые ссылки офферов',
  landing_target_link: 'Лендинги офферов',
  expand: 'Развернуть',
  device_type_desktop: 'Персональный компьютер',
  device_type_mobile_phone: 'Мобильный телефон',
  device_type_mobile_device: 'Мобильное устройство',
  device_type_tablet: 'Планшет',
  device_type_tv_device: 'Телевизор',
  device_type_digital_camera: 'Цифровая камера',
  device_type_car_entertainment_system: 'Развлекательная система автомобиля',
  device_type_ebook_reader: 'Электронная книга',
  device_type_console: 'Консоль',
  show_user_contacts_filter: 'Показать фильтры по контактам',
  hide_user_contacts_filter: 'Скрыть фильтры по контактам',
  pin: 'Закрепить',
  unpin: 'Открепить',
  exclude: 'Исключить',
  exclude_help: 'Выбранные элементы будут исключены',
  exclude_preview: 'Все, искл.:',
  activity_check_text: 'Данные были обновлены',
  activity_check_refresh: 'Обновить',
  filters: 'Фильтры',
  search: 'Поиск',
  chosen: 'Выбраны',
};
