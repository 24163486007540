import { useCallback, useEffect, useMemo } from 'react';
import { Dispatch } from 'redux';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import defaultTo from 'lodash/defaultTo';
import toNumber from 'lodash/toNumber';
import toLower from 'lodash/toLower';
import isEmpty from 'lodash/isEmpty';
import assign from 'lodash/assign';
import sortBy from 'lodash/sortBy';
import isNil from 'lodash/isNil';
import some from 'lodash/some';
import map from 'lodash/map';

import { getApi } from 'core/api';
import { IDictState } from 'models/IDictState';
import { IDataProvider } from 'models/IDataProvider';
import { IOptions } from 'models/IOptions';
import { Dicts } from 'constants/enums/Dicts';
import {
  dictInitialState,
  requestDict,
  setInitialized,
} from 'core/redux/slices/dicts';
import { ICountry } from 'models/ICountry';
import { IRule } from 'models/IRule';
import timezones from 'constants/timezones';
import { UserType } from 'constants/enums/UserType';
import { getUserType } from 'utils/storageUtils';
import { ILanguage } from 'models/ILanguage';
import { IDictValue } from 'models/IDictValue';
import { IProduct } from 'models/IProduct';
import { IPromoCodeType } from 'models/IPromoCodeType';
import { UserStatus } from 'constants/enums/status';
import ContactUserType from 'constants/enums/ContactUserType';
import { IOsType } from 'models/IOsType';
import { hashCountryCodes } from 'components/CountryLabel/utils';
import { isAdmin } from 'utils/userUtils';
import { ICurrency } from 'models/ICurrency';
import emptyObject from 'utils/emptyObject';
import { IReduxState } from 'models/IReduxState';

export interface IDictData<T> extends IDictState<T> {
  reload: () => void;
}

interface IDictRestrictions {
  /** Types of users who can request the dictionary */
  allowedUserTypes?: UserType[];
  /** Types of users who can't request the dictionary */
  notAllowedUserTypes?: UserType[];
}

const mapToArray =
  <T extends { id: unknown } = any>(labelField: keyof T) =>
  (dict: { [code: string]: T['id'] }) => {
    return map(
      dict,
      (value, key) =>
        ({
          id: value,
          [labelField]: key,
        } as T),
    );
  };

/**
 *  Store API configuration for each dictionary.
 *
 *  The functions are defined using conditional expressions to determine
 *  which API method to call based on certain conditions,
 *  such as whether the user is an admin or not.
 */
const DATA_PROVIDERS: {
  [name: string]: (api: any) => IDataProvider;
} = {
  [Dicts.Countries]: (api) => api?.Dict?.GET_COUNTRIES,
  [Dicts.AdminFieldTypes]: (api) => api?.Contact?.GET_CONTACTS,
  [Dicts.PartnerFieldTypes]: (api) =>
    isAdmin() ? api?.Contact?.GET_PARTNER_CONTACTS : api?.Contact?.GET_CONTACTS,
  [Dicts.AdvertiserFieldTypes]: (api) =>
    isAdmin()
      ? api?.Contact?.GET_ADVERTISER_CONTACTS
      : api?.Contact?.GET_CONTACTS,
  [Dicts.Rules]: (api) => api?.Admin?.GET_RULES,
  [Dicts.Tags]: (api) => api?.Tag?.GET_TAGS,
  [Dicts.PartnerTags]: (api) => api?.Tag?.GET_PARTNER_TAGS,
  [Dicts.GeneralSettings]: (api) => api?.Settings?.GET_GENERAL_SETTINGS,
  [Dicts.Offers]: (api) => api?.Offer?.GET_OFFERS,
  [Dicts.OffersActive]: (api) => api?.Offer?.GET_OFFERS,
  [Dicts.Admins]: (api) => api?.Admin?.GET_ADMINS,
  [Dicts.Roles]: (api) => api?.Role?.GET_ROLES,
  [Dicts.Partners]: (api) => api?.Partner?.GET_PARTNERS,
  [Dicts.PartnersActive]: (api) => api?.Partner?.GET_PARTNERS,
  [Dicts.PartnersAll]: (api) => api?.Partner?.GET_ALL_PARTNERS,
  [Dicts.StatisticVariables]: (api) =>
    api?.Statistics?.GET_STATISTICS_VARIABLES,
  [Dicts.Products]: (api) => api?.Product?.GET_PRODUCT_LIST,
  [Dicts.Currencies]: (api) => api?.Currency?.GET_ENABLED_LIST,
  [Dicts.CurrenciesAll]: (api) => api?.Currency?.GET_LIST,
  [Dicts.Browsers]: (api) => api?.Statistics?.GET_BROWSER_LIST,
  [Dicts.Os]: (api) => api?.Statistics?.GET_OS_LIST,
  [Dicts.DeviceTypes]: (api) => api?.Statistics?.GET_DEVICE_TYPE_LIST,
  [Dicts.OsTypes]: (api) => api?.Dict?.GET_OS_TYPES,
  [Dicts.GroupedOsTypes]: (api) => api?.Dict?.GET_OS_TYPES,
  [Dicts.OfferDeviceTypes]: (api) => api?.Dict?.GET_DEVICE_TYPES,
  [Dicts.PaymentSystems]: (api) => api?.PaymentSystem?.GET_PAYMENT_SYSTEMS,
  [Dicts.Languages]: (api) => api?.Dict?.GET_DICTIONARY,
  [Dicts.ImgFormats]: (api) => api?.Dict?.GET_DICTIONARY,
  [Dicts.ExportTypes]: (api) => api?.Dict?.GET_DICTIONARY,
  [Dicts.ConversionStatusDescriptions]: (api) => api?.Dict?.GET_DICTIONARY,
  [Dicts.Advertisers]: (api) => api?.Advertiser?.GET_ADVERTISERS_LIST,
  [Dicts.EventGroups]: (api) => api?.EventGroup?.GET_EVENT_GROUPS,
  [Dicts.GoalGroups]: (api) => api?.GoalGroup?.GET_GOAL_GROUPS,
  [Dicts.Categories]: (api) => api?.Category?.GET_CATEGORIES,
  [Dicts.Balances]: (api) => api?.Withdrawal?.GET_BALANCE,
  [Dicts.PromoCodeTypes]: (api) => api?.PromoCode?.GET_TYPES,
  [Dicts.PromoCodeGroupedTypes]: (api) => api?.PromoCode?.GET_TYPES,
};

/**
 *  Specifies the allowed or not allowed user types for accessing a dictionary.
 */
const RESTRICTIONS: {
  [name: string]: IDictRestrictions;
} = {
  [Dicts.OffersActive]: {
    allowedUserTypes: [UserType.ADMIN, UserType.PARTNER],
  },
  [Dicts.Partners]: {
    allowedUserTypes: [UserType.ADMIN],
  },
  [Dicts.PartnersActive]: {
    allowedUserTypes: [UserType.ADMIN],
  },
  [Dicts.PartnerTags]: {
    allowedUserTypes: [UserType.ADMIN],
  },
  [Dicts.PartnersAll]: {
    allowedUserTypes: [UserType.ADMIN],
  },
  [Dicts.Products]: {
    notAllowedUserTypes: [UserType.PARTNER],
  },
  [Dicts.Advertisers]: {
    notAllowedUserTypes: [UserType.ADVERTISER],
  },
  [Dicts.ExportTypes]: {
    notAllowedUserTypes: [UserType.ADVERTISER],
  },
  [Dicts.CurrenciesAll]: {
    notAllowedUserTypes: [UserType.ADVERTISER],
  },
  [Dicts.EventGroups]: {
    allowedUserTypes: [UserType.ADMIN],
  },
  [Dicts.GoalGroups]: {
    notAllowedUserTypes: [UserType.ADVERTISER],
  },
  [Dicts.Categories]: {
    allowedUserTypes: [UserType.ADMIN],
  },
  [Dicts.Admins]: {
    allowedUserTypes: [UserType.ADMIN],
  },
  [Dicts.Balances]: {
    allowedUserTypes: [UserType.PARTNER],
  },
  [Dicts.PromoCodeTypes]: {
    allowedUserTypes: [UserType.ADMIN],
  },
  [Dicts.PromoCodeGroupedTypes]: {
    allowedUserTypes: [UserType.ADMIN],
  },
  [Dicts.PaymentSystems]: {
    notAllowedUserTypes: [UserType.ADVERTISER],
  },
  [Dicts.OsTypes]: {
    allowedUserTypes: [UserType.ADMIN, UserType.PARTNER],
  },
  [Dicts.GroupedOsTypes]: {
    allowedUserTypes: [UserType.ADMIN, UserType.PARTNER],
  },
  [Dicts.OfferDeviceTypes]: {
    allowedUserTypes: [UserType.ADMIN],
  },
};

/** Additional fetch parameters for loading a dictionary  */
const getOptions = (
  userType?: UserType,
): {
  [name: string]: IOptions;
} => ({
  [Dicts.OffersActive]: {
    queryMapping: {
      filter:
        userType === UserType.PARTNER ? { is_available: 1 } : { status: 1 },
    },
  },
  [Dicts.PartnersActive]: {
    queryMapping: { filter: { status: [UserStatus.Activated] } },
  },
  [Dicts.AdminFieldTypes]: {
    queryMapping: { filter: { user_type: ContactUserType.Admin } },
  },
  [Dicts.Languages]: {
    queryMapping: { dict: 'lang' },
  },
  [Dicts.ImgFormats]: {
    queryMapping: { dict: 'image_format' },
  },
  [Dicts.ExportTypes]: {
    queryMapping: { dict: 'export_type' },
  },
  [Dicts.ConversionStatusDescriptions]: {
    queryMapping: { dict: 'conversion_rejected_description' },
  },
});

/**
 *  Functions that localize the data of the dictionary.
 */
const LOCALIZE_FUNCTIONS: {
  [name: string]: (t: TFunction) => (data: any) => any;
} = {
  [Dicts.Countries]: (t: TFunction) => (country: ICountry) => {
    return assign({}, country, { name: t(`countries:${country.iso}`) });
  },
  [Dicts.Rules]: (t: TFunction) => (rule: IRule) => {
    return assign({}, rule, { label: t(`rules:${rule.slug}`) });
  },
} as const;

/**
 *  Functions that process the data of the dictionary.
 *  Each function takes in the data of the dictionary and returns a modified version of it.
 */
const PROCESSING_FUNCTIONS: {
  [name: string]: Array<(data: any) => any>;
} = {
  [Dicts.Languages]: [(dict) => mapToArray<ILanguage>('code')(dict?.lang)],
  [Dicts.ImgFormats]: [
    (dict) => mapToArray<IDictValue>('code')(dict?.image_format),
  ],
  [Dicts.Countries]: [
    (dict) => {
      hashCountryCodes(dict);
      return sortBy(dict, 'name');
    },
  ],
  [Dicts.CurrenciesAll]: [
    (dict) => {
      return map(dict, (d: ICurrency) => ({
        ...d,
        rate: toNumber(d.rate),
      }));
    },
  ],
  [Dicts.Currencies]: [
    (dict) => {
      return map(dict, (d: ICurrency) => ({
        ...d,
        rate: toNumber(d.rate),
      }));
    },
  ],
  [Dicts.ConversionStatusDescriptions]: [
    (dict) =>
      map(
        mapToArray<IDictValue>('code')(dict?.conversion_rejected_description),
        (d) => ({ ...d, code: d.code?.toLowerCase() }),
      ),
  ],
  [Dicts.ExportTypes]: [
    (dict) =>
      map(mapToArray<IDictValue>('code')(dict?.export_type), (d) => ({
        ...d,
        code: d.code?.toLowerCase(),
      })),
  ],
  [Dicts.PromoCodeGroupedTypes]: [
    (types) => {
      const indexes: Record<number, number> = {};
      const result: Array<IProduct & { options: IPromoCodeType[] }> = [];
      if (types) {
        for (const type of types) {
          if (type.product) {
            const index = indexes[type.product.id];
            if (isNil(index)) {
              indexes[type.product.id] = result.length;
              result.push({
                ...type.product,
                options: [type],
              });
            } else {
              result[index].options.push(type);
            }
          }
        }
      }
      return sortBy(result, (group) => group.id * -1);
    },
  ],
  [Dicts.GroupedOsTypes]: [
    (osTypes) => {
      const indexes: Record<number, number> = {};
      const result: Array<IOsType & { options: IOsType[] }> = [];
      if (osTypes) {
        for (const type of osTypes) {
          if (type.family_name) {
            const index = indexes[type.family_name];
            if (isNil(index)) {
              indexes[type.family_name] = result.length;
              result.push({
                id: type.family_name,
                name: type.family_name,
                family_name: type.family_name,
                options: [type],
              });
            } else {
              result[index].options.push(type);
            }
          }
        }
      }
      result.sort((a, b) => {
        const aName = a.name;
        const bName = b.name;
        if (aName === bName) {
          return 0;
        }
        if (toLower(a.family_name) === 'other') {
          return 1;
        }
        if (toLower(b.family_name) === 'other' || bName > aName) {
          return -1;
        }
        return 1;
      });
      return result;
    },
  ],
};

/**
 * Dictionaries that don't require loading.
 */
const CONSTANT_DICTS: {
  [name: string]: any;
} = {
  [Dicts.UtcOffsets]: timezones,
};

/**
 * For instant notify other instance of the hook that loading was started already.
 * Instant changes compared to redux actions but less reliability.
 * We have to use both options
 */
const DICTS_LOADING: Record<string, boolean> = {
  [Dicts.UtcOffsets]: false,
};

/**
 * React Hook that provides access to dictionary data, including loading,
 * caching and reloading functionality.
 * @typeParam T - Type of the dictionary value
 * @param name - The name of the dictionary. It is used to
 * identify which dictionary to load and retrieve data from.
 * @param [canLoad=true] - Determines whether the dictionary should be loaded or not.
 * If `canLoad` is `false`, the dictionary will not be loaded.
 * @returns {@link IDictData} state of requested dictionary
 */
const useDict = <T = any>(name: Dicts, canLoad = true): IDictData<T> => {
  const { t } = useTranslation('countries');
  const dispatch = useDispatch<Dispatch>();
  const dictStateRaw: IDictState<T> = useSelector(
    (state: IReduxState) => state.dicts[name],
    shallowEqual,
  );
  let dictState = dictStateRaw ?? emptyObject;
  const api = getApi();
  const userType = getUserType();

  // if dictionary is a constant
  // it returned without loading
  if (CONSTANT_DICTS[name]) {
    dictState = {
      value: CONSTANT_DICTS[name],
      loading: false,
      initialized: true,
    };
  }

  const reload = useCallback(() => {
    dispatch(setInitialized({ name, initialized: false }));
  }, [dispatch]);

  const getDictInitProps = useCallback(
    (
      name: Dicts,
    ): {
      dataProvider: IDataProvider;
      options: IOptions;
      localizeFn: ((data: any) => any) | undefined;
      processingFns: Array<(data: any) => any> | undefined;
    } => {
      const dataProvider = DATA_PROVIDERS[name];
      if (!dataProvider) {
        throw `Didn't found data provider for "${name}" dict.`;
      }
      const localizeFnCreator = LOCALIZE_FUNCTIONS[name];
      const processingFns = PROCESSING_FUNCTIONS[name];
      return {
        dataProvider: dataProvider(api),
        options: defaultTo(getOptions(getUserType())[name], {}),
        localizeFn: localizeFnCreator ? localizeFnCreator(t) : undefined,
        processingFns: processingFns,
      };
    },
    [api],
  );

  useEffect(() => {
    const { loading, error, value, initialized } = dictState;
    let requestAllowed = true;
    if (RESTRICTIONS[name]?.allowedUserTypes) {
      requestAllowed = some(
        RESTRICTIONS[name]?.allowedUserTypes,
        (type) => type === userType,
      );
    } else if (RESTRICTIONS[name]?.notAllowedUserTypes) {
      requestAllowed = !some(
        RESTRICTIONS[name]?.notAllowedUserTypes,
        (type) => type === userType,
      );
    }
    if (
      canLoad &&
      !loading &&
      requestAllowed &&
      (!initialized || (isNil(value) && (!error || isEmpty(error))))
    ) {
      if (!DICTS_LOADING[name]) {
        DICTS_LOADING[name] = true;
        dispatch(
          requestDict({
            name,
            ...getDictInitProps(name),
          }),
        );
      }
    } else if (DICTS_LOADING[name]) {
      DICTS_LOADING[name] = false;
    }
  }, [name, userType, dictState?.initialized, canLoad]);

  const resultState = isNil(dictState.value) ? dictInitialState : dictState;
  const isLoading = dictState.loading || !dictState.initialized;
  return useMemo(
    () => ({
      ...resultState,
      loading: isLoading,
      reload,
    }),
    [resultState, isLoading, reload],
  );
};

export default useDict;
