import React, { useContext, useRef, useState } from 'react';
import uniqid from 'uniqid';
import { Placement } from '@floating-ui/core';
import {
  useFloating,
  useInteractions,
  useHover,
  useClick,
  useFocus,
  autoPlacement,
  autoUpdate,
  offset,
  flip,
  arrow,
  FloatingArrow,
  FloatingPortal,
  useMergeRefs,
  UseHoverProps,
  useDismiss,
  shift,
  limitShift,
  safePolygon,
} from '@floating-ui/react';
import includes from 'lodash/includes';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import { LayoutContext } from 'core/components/Layout/LayoutProvider';

const ARROW_HEIGHT = 5;
const ARROW_WIDTH = 10;
const GAP = 2;

export type OverlayTriggerType = 'hover' | 'click' | 'focus';

export interface ITooltipProps {
  id?: string;
  trigger?: OverlayTriggerType | OverlayTriggerType[] | null;
  delay?: UseHoverProps['delay'];
  show?: boolean;
  overlay: React.ReactNode;
  children: React.ReactNode;
  placement?: Placement;
  container?: HTMLElement | null | React.MutableRefObject<HTMLElement | null>;
}

export type TooltipOptions = Omit<ITooltipProps, 'children'>;

function Tooltip({
  id,
  placement,
  delay,
  trigger: triggerProp,
  overlay,
  children,
  container,
  show,
}: ITooltipProps): React.JSX.Element {
  const [defaultId] = useState<string>(uniqid());
  const [isOpen, setIsOpen] = useState(show);
  const arrowRef = useRef(null);
  const isTouchScreen = window.matchMedia('(pointer: coarse)').matches;

  let trigger = triggerProp;

  if (!trigger) {
    if (isTouchScreen) {
      trigger = 'click';
    } else {
      trigger = ['hover', 'focus'];
    }
  }

  const needDismissFeature =
    isTouchScreen ||
    trigger === 'click' ||
    (isArray(trigger) && includes(trigger, 'click'));

  const { layoutRef } = useContext(LayoutContext);

  const autoPlacementArray = isNil(placement)
    ? [
        autoPlacement({
          alignment: 'start',
          rootBoundary: 'viewport',
          boundary: layoutRef?.current ?? undefined,
          elementContext: 'reference',
        }),
      ]
    : [];

  const flipArray = isNil(placement) ? [] : [flip()];

  const { refs, context, floatingStyles } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: placement,
    whileElementsMounted(referenceEl, floatingEl, update) {
      const cleanup = autoUpdate(referenceEl, floatingEl, update, {
        ancestorScroll: true,
        ancestorResize: true,
        elementResize: true,
        layoutShift: true,
      });
      return cleanup;
    },
    middleware: [
      offset(ARROW_HEIGHT + GAP),
      ...flipArray,
      ...autoPlacementArray,
      shift({
        limiter: limitShift(),
      }),
      arrow({
        element: arrowRef,
      }),
    ],
  });

  const hover = useHover(context, {
    enabled:
      trigger === 'hover' || (isArray(trigger) && includes(trigger, 'hover')),
    // mouseOnly: true,
    delay: delay ?? {
      open: 300,
      close: 0,
    },
    handleClose: safePolygon({
      requireIntent: false,
    }),
  });
  const focus = useFocus(context, {
    enabled:
      trigger === 'focus' || (isArray(trigger) && includes(trigger, 'focus')),
  });
  const click = useClick(context, {
    enabled:
      trigger === 'click' || (isArray(trigger) && includes(trigger, 'click')),
  });
  const dismiss = useDismiss(context, {
    enabled: needDismissFeature,
    outsidePress: true,
    referencePress: false,
    outsidePressEvent: 'click',
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    click,
    dismiss,
  ]);

  const child = React.Children.toArray(children)?.[0];
  const ref = useMergeRefs([refs.setReference, (child as any)?.ref]);

  if (!overlay) {
    return <>{children}</>;
  }

  return (
    <>
      {React.Children.map(children, (child) => {
        if (!child) {
          return null;
        }
        const elem = child as React.ReactElement;
        return React.cloneElement(elem, {
          ref: ref,
          ...getReferenceProps(elem?.props),
        });
      })}
      {isOpen && (
        <FloatingPortal root={container ?? layoutRef?.current}>
          <div
            key={id ?? defaultId}
            className="tooltip show"
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <div className="tooltip-inner">{overlay}</div>
            <FloatingArrow
              ref={arrowRef}
              className="tooltip-arrow"
              context={context}
              height={ARROW_HEIGHT}
              width={ARROW_WIDTH}
            />
          </div>
        </FloatingPortal>
      )}
    </>
  );
}

export default Tooltip;
