export default {
  date_or_period: 'Date or period',
  offer: 'Offer',
  country: 'Country',
  advertiser: 'Advertiser',
  product: 'Product',
  offer_and_product: '$t(statistics:offer)/$t(statistics:product)',
  partner: 'Partner',
  no_statistics: 'No statistics',
  cohort_no_statistics:
    'To generate a cohort analysis, set the cohort settings, filter parameters, and click the "Apply" button',
  statistics: 'Statistics',
  common: 'Common',
  sub_id: 'Sub ID',
  custom_field: 'Custom Field',
  show_filter_by_sub_id: 'Show Filter by Sub ID',
  show_filter_by_custom_field: 'Show Filter by Custom',
  conversions: 'Conversions',
  click_id: 'Click ID',
  clicks: 'Clicks',
  unique_clicks: 'Unique clicks',
  traffic: 'Traffic',
  referer: 'Referer',
  qualifications: 'Qualifications',
  conversion_id: 'Conversion ID',
  goal: 'Goal',
  events: 'Events',
  event_id: 'Event ID',
  event_key: 'Event key',
  event: 'Event',
  idempotency_key: 'Idempotency key',
  landing: 'Landing',
  date: 'Date',
  value: 'Value',
  traffic_source: 'Traffic source',
  traffic_source_lead_help:
    'When grouping by Sub ID 1 - Sub ID 10, you can select the traffic source either Referral Links and Promo Codes or Leads.',
  traffic_source_click_disabled_help:
    'To select Referral link, deselect Leads.',
  traffic_source_promo_disabled_help: 'To select Promo Codes, deselect Leads.',
  traffic_source_leads_disabled_help:
    'To select Leads, deselect Referral link and Promo Codes.',
  link_source: 'Link source',
  qualification_id: 'Qualification ID',
  benefit: 'Benefit',
  denied: 'Denied',
  deny: 'Deny',
  group: 'Group',
  by: 'By',
  city: 'City',
  group_by: 'Group by',
  group_by_add: 'Group by',
  group_by_day: 'days',
  group_by_hour: 'hours',
  group_by_offer: 'offers',
  group_by_advertiser: 'advertisers',
  group_by_product: 'products',
  group_by_partner: 'partners',
  group_by_geo: 'countries',
  group_by_city: 'cities',
  group_by_os: 'operating systems',
  group_by_device: 'devices',
  group_by_landing: 'landings',
  group_by_offer_tags: 'offer tags',
  group_by_partner_tags: 'partner tags',
  group_by_promocodes: 'promo codes',
  group_by_sub_id: 'Sub ID {{subIdNumber}}',
  group_by_custom_field: 'custom{{customNumber}}',
  group_by_days_7: '7 days',
  group_by_days_10: '10 days',
  group_by_days_15: '15 days',
  group_by_month: 'month',
  sub_id_number: 'Sub ID {{subIdNumber}}',
  custom_field_number: 'custom{{customNumber}}',
  column_date: 'Date',
  column_hour: 'Hour',
  column_month: 'Month',
  column_offer: 'Offer',
  column_count: 'Count',
  column_advertiser: 'Advertiser',
  column_product: 'Product',
  column_partner: 'Partner',
  column_geo: 'Country',
  column_city: 'City',
  column_os: 'Operating system',
  column_device: 'Device',
  column_landing: 'Landing',
  column_tag: 'Tags',
  column_region: 'Region',
  column_region_all: '$t(statistics:column_city)/$t(statistics:column_region)',
  group_empty: 'Empty',
  group_unknown: 'Unknown',
  qualification_deny_confirm: 'Do you want to deny the qualification?',
  incomes: 'Income',
  payouts: 'Payout',
  profit: 'Profit',
  show_events: 'Show events',
  show_conversions: 'Show conversions',
  offer_goals: 'Offer goals',
  offer_conversions: '{{offer}} offer conversions',
  product_events: '{{product}} product events',
  amount: 'Amount',
  amount_in_dollars: 'Amount in dollars',
  amount_in_original_currency: 'Amount in original currency',
  name_and_flag: 'Name and flag',
  date_and_time: 'Date and time',
  date_and_time_of_click: 'Date and time of click',
  date_and_time_all:
    '$t(statistics:date_and_time)/$t(statistics:date_and_time_of_click)',
  condition_id: 'Condition ID',
  its_number_in_the_database: 'Its number in the database',
  for_webmaster: 'Reward',
  payout_for_webmaster: 'Payout for webmaster',
  amount_and_currency: 'Amount and currency',
  tag: 'Tags',
  offer_tags: 'Offer tags',
  partner_tags: 'Partner tags',
  timezone: 'Timezone',
  cohort_analysis: 'Cohort analysis',
  cohort_settings: 'Cohort settings',
  cohort_period: 'Cohort period',
  cohort_condition: 'Cohort condition',
  cohort_period_group: 'Cohort period group',
  logs: 'Logs',
  grouping_cohort_day: 'By days',
  grouping_cohort_week: 'By weeks',
  grouping_cohort_month: 'By months',
  cohort_condition_clicks: 'Clicks',
  common_statistics: 'Common statistics',
  webmaster: 'Webmaster',
  qualification: 'Qualification',
  conversion_name: 'Conversion name',
  lead_id: 'Lead ID',
  name: 'Name',
  hint: 'Hint',
  formula: 'Formula',
  add_custom_column: 'Add custom column',
  edit_custom_column: 'Edit custom column',
  add_formula: 'Add formula',
  edit_formula: 'Edit formula',
  formula_output_type_amount: 'Amount',
  formula_output_type_average: 'Average',
  variables_click: 'Traffic',
  variables_event: 'Events',
  variables_goal: 'Goals',
  variables_conversion: 'Conversions',
  variables_column: 'Value of column',
  variables_column_disabled:
    "Column is using value of current column in its formula. Columns can't refer to each other.",
  variable_click_count: 'Hosts',
  variable_click_unique_count: 'Unique clicks',
  variable_count: '<span class="variable-count">Count</span>',
  variable_amount: '<span class="variable-amount">Amount</span>',
  variable_count_of: '<span class="variable-count">Count of</span>',
  variable_amount_of: '<span class="variable-amount">Amount of</span>',
  variable_event_count: '$t(statistics:variable_count_of) events',
  variable_event_sum: '$t(statistics:variable_amount_of) events',
  variable_goal_count: '$t(statistics:variable_count_of) conversions',
  variable_goal_sum: '$t(statistics:variable_amount_of) conversions',
  variable_event_group_count: '$t(statistics:variable_count). Event groups',
  variable_event_group_sum: '$t(statistics:variable_amount). Event groups',
  variable_goal_group_count: '$t(statistics:variable_count). Goal groups',
  variable_goal_group_sum: '$t(statistics:variable_amount). Goal groups',
  variable_goal_group_first_date: 'Date of first conversion. Goal groups',
  variable_goal_group_last_date: 'Date of last conversion. Goal groups',
  variable_conversion_count: 'Count of conversions',
  variable_conversion_income: 'Income for conversions by CPA model',
  variable_conversion_amount: 'Payments for conversions by CPA model',
  variable_conversion_count_0: '$t(statistics:variable_conversion_count)',
  variable_conversion_income_0: '$t(statistics:variable_conversion_income)',
  variable_conversion_amount_0: '$t(statistics:variable_conversion_amount)',
  variable_conversion_count_1: '$t(statistics:variable_conversion_count)',
  variable_conversion_income_1: '$t(statistics:variable_conversion_income)',
  variable_conversion_amount_1: '$t(statistics:variable_conversion_amount)',
  variable_conversion_count_2: '$t(statistics:variable_conversion_count)',
  variable_conversion_income_2: '$t(statistics:variable_conversion_income)',
  variable_conversion_amount_2: '$t(statistics:variable_conversion_amount)',
  variable_conversion_count_3: '$t(statistics:variable_conversion_count)',
  variable_conversion_income_3: '$t(statistics:variable_conversion_income)',
  variable_conversion_amount_3: '$t(statistics:variable_conversion_amount)',
  variable_conversion_count_4: '$t(statistics:variable_conversion_count)',
  variable_conversion_income_4: '$t(statistics:variable_conversion_income)',
  variable_conversion_amount_4: '$t(statistics:variable_conversion_amount)',
  variable_conversion_revshare_income:
    'Income for conversions by Revshare model',
  variable_conversion_revshare_amount:
    'Payments for conversions by Revshare model',
  variable_conversion_cpm_income: 'Income for conversions by CPM model',
  variable_conversion_cpm_amount: 'Payments for conversions by CPM model',
  variable_conversion_cpm_count: 'Number of actions (CPM)',
  variable_active_users_count: 'Count of active users',
  qualification_0: 'hold',
  qualification_1: 'paid',
  qualification_2: 'canceled',
  qualification_3: 'over limit',
  qualification_4: 'pending',
  add_column: 'Add column',
  column_clicks: 'Total number of clicks',
  column_unique_clicks: 'Unique clicks count',
  column_income: 'Income',
  column_qualifications_count_payed:
    'Count of conversions in status "Confirmed"',
  column_conversions_count_payed: 'Count of conversions in status "Confirmed"',
  column_income_payed: 'Income for conversions in status "Confirmed"',
  column_payments: 'Payments',
  column_payments_payed: 'Payments for conversions in status "Confirmed"',
  table_settings: 'Table settings',
  default_cohort_settings_tooltip:
    'Cohort analysis is not configured. Customize the cohort analysis by adding columns with the required indicators.',
  clear_all: 'Clear all',
  admin_scheme: 'Table for administrators',
  partner_scheme: 'Table for partners',
  reset_table_scheme: 'Restore default table settings?',
  cohort_select_product: 'Select product to get a cohort analysis',
  status_description: 'Reason for rejection',
  revshare_model: 'Revshare',
  payment_model: 'Payout model',
  conversions_form_revshare_warning:
    'Among the selected ones there is a conversion <span class="font-weight-bold fw-bold">by formula</span>. Payment and income on it will not change.',
  tid: 'ID transaction',
  in_pending: 'Pending',
  in_hold: 'Hold',
  in_confirmed: 'Confirmed',
  in_rejected: 'Rejected',
  pay_out: 'Payout',
  partner_manager: 'Partner manager',
  cancel: 'Deny',
  browser: 'Browser',
  os: 'OS',
  os_version: 'OS Version',
  click_ip: 'Click IP',
  device_type: 'Device type',
  goal_key: 'Goal key',
  payout_value: 'Payout value',
  payout_currency: 'Payout currency',
  income_value: 'Income value',
  income_currency: 'Income currency',
  note: 'Note',
  changes_in_data_affect_profits: 'Changes in data affect profits',
  conversions_changed: 'The conversions has updated successfully',
  all_conversions_changed: '{{count}} first conversions successfully changed',
  select_all_conversions_1: 'Select',
  select_all_conversions_2: 'conversions',
  no_landing: 'No landing',
  for: 'For',
  for_selected_set: 'selected set:',
  for_selected: 'selected:',
  edited_by_admin: 'Edited by admin',
  custom_column_formula_placeholder:
    'Input column value calculation formula using parameters and operations below',
  output_format: 'Output format',
  output_format_type: 'Output Format "Total"',
  output_format_help:
    'Enter any text and add prefixes. This format will be used to display information in statistics.',
  per_page: 'per page',
  conversions_import_main_button: 'Edit conversions via CSV',
  conversions_import_title: 'Change conversion via CSV',
  conversions_import_button: 'Change conversions',
  conversions_import_go_back: 'Go to statistics',
  conversions_import_file_accepted: 'File accepted',
  conversions_import_file_not_processed: 'File not processed',
  conversions_import_success:
    'File accepted. Processing will take some time. All conversions found from the file will be modified.',
  conversions_import_fail_1: 'There are critical errors at the file.',
  conversions_import_fail_2: 'Conversions cannot be changed.',
  conversions_import_help_1:
    'For existing conversions, the new settings will be applied.',
  conversions_import_help_2: 'New conversions will not be created.',
  conversions_import_help_3:
    "Use delimiters (comma ',' or semicolon ';') in your CSV file. 2 delimiters in the same file cannot be specified at the same time.",
  filter_promo_code: 'Promo code',
  filter_promo_code_user_id: 'User ID',
  filter_fraud_risk_level: 'Fraud risk level',
  for_admins: 'For admins',
  for_partners: 'For partners',
  all_partners: 'all',
  table_set_as_default: 'The table has been set as default',
  default_for_admins: 'Default for admins',
  default_for_partners: 'Default for partners',
  set_default_for_admins: 'Set as default for admins',
  set_default_for_partners: 'Set as default for partners',
  table_for_admins: 'Table for administrators',
  cohort_table_for_admins: 'Cohort analysis',
  table_for_partners: 'Table for partners',
  new_table_for_admins: 'New table for administrators',
  new_table_for_partners: 'New table for partners',
  new_table_limit_title: 'Limit reached',
  new_table_limit_text:
    'You can create max {{count}} tables for admins or for partners. Delete unnecessary tables and create new instead.',
  limit_placeholder: 'From 1 to 1 000 000',
  table_default_partners: 'Default table available for all partners',
  scheme_data_settings: 'Settings',
  scheme_data_duplicate: 'Duplicate table',
  scheme_remove_confirm: 'Do you want to delete the table',
  scheme_remove_default_title: 'The table can’t be deleted',
  scheme_remove_default_text_admins:
    'This table set as default for all admins. To delete it, first select another default table.',
  scheme_remove_default_text_partners:
    'This table set as default for all partners. To delete it, first select another default table.',
  status_note: 'Note',
  comment_to_partner: 'Comment to partner',
  comment: 'Comment',
  to_group: 'Group',
  group_name: 'Group name',
  group_together: 'Group together',
  add_to_group: 'Add to the group',
  conversions_columns_settings: 'Configure columns',
  table_columns: 'Table columns',
  deleted: 'deleted',
  customers_statistics: 'Customers',
  customers_goal_group: 'Goal group',
  customers_user: 'Customer',
  customers_cohort_period: 'Period of conversions for the selected goal group',
  invoice_statuses: 'Payment status',
  status_not_paid: 'Not paid',
  status_awaiting_payment: 'Pending payment',
  status_payed: 'Paid out',
  share: 'Share',
  link_copied: 'Link copied to clipboard',
  to_filter: 'Filter',
  go_to_card: 'Go to the card',
  go_to_card_offer: 'Go to the card of the offer',
  go_to_card_partner: 'Go to partner card',
  set_filters: 'Set filters',
};
