export default {
  'edit profile': 'Edit profile',
  'change password': 'Change password',
  created: 'Created',
  active: 'Active',
  banned: 'Banned',
  incomplete_registration: 'Incomplete registration',
  on_moderation: 'On moderation',
  rejected: 'Rejected',
  disabled: 'Disabled',
  enabled: 'Enabled',
  activate: 'Activate',
  reject: 'Reject',
  account_is_not_confirmed_yet: 'Account is not confirmed yet. ',
  to_activate_the_user: 'Do you want to activate this user?',
  postback_settings: 'Postback settings',
  event_postback_settings_label: 'Link for sending a postback with events',
  event_postback_settings_help:
    'URL to send postbacks with events. You can copy the link.',
  goal_postback_settings_label: 'Link for sending a postback with goals',
  goal_postback_settings_help:
    'URL to send postbacks with goals. You can copy the link.',
  relation_postback_settings_help:
    'URL to send resale link postbacks. You can copy the link.',
  profile_approval_has_been_rejected: 'The user approval has been rejected',
  profile_approval_confirm: 'Do you want to activate the user?',
  profile_reject_confirm: 'Do you want to reject the user?',
  manager: 'Manager',
  contacts: 'Contacts',
  manager_contacts: 'Contacts of manager',
  client_key: 'Client key',
  your_client_key: 'Your client key',
  generate_new_client_key: 'Generate new client key',
  client_key_help:
    'The Client key is used by affiliate system to identify user when postback is received. You can copy Client key. Also you can generate a new one.',
  send_follow_up_email: 'Send follow-up email',
  email_has_been_sent: 'The email has been sent',
  client_key_generate_confirm:
    'Are you sure you want to update your client key? After client key update, postbacks with the old key will no longer be accepted.',
  partner_invite_link: 'Link to invite partners',
  api_key: 'API-key',
  api_key_update_title: 'Generate a new key',
  api_key_update_confirm:
    'Do you want to generate a new API key? Queries with old key will not work.',
  api_key_updated: 'API-key updated successfully',
  tags: 'Tags',
  source_of_registration: 'Source of registration',
  secret_key: 'Secret Key',
  secret_key_help:
    'The secret key is used to verify the integrity of the transmitted data when sending and receiving postback',
  generate_new_secret_key: 'Generate a new secret key',
  secret_key_generate_confirm:
    'Are you sure you want to update your secret key? After secret key update, postbacks with the old key will no longer be accepted.',
};
