export default {
  roles: 'Роли',
  role: 'Роль',
  'new role': 'Новая роль',
  'removal was successful': 'Удаление прошло успешно',
  'an error has occurred': 'Произошла ошибка',
  'do you want to delete role': 'Вы хотите удалить роль',
  name: 'Название',
  rights: 'Права',
  show: 'Показать',
  'name of role': 'Название роли',
  'edit role': 'Редактирование роли',
  'role successfully created': 'Роль успешно создана',
  'role successfully updated': 'Роль успешно обновлена',
  'no roles': 'Нет созданных ролей',
  accesses: 'Доступы',
  role_rights: 'Права роли',
  role_show_right_tooltip:
    'Вы не можете отключить роль просмотра, пока роли создания\\изменения активны.',
};
