export default {
  date_or_period: 'Дата или период',
  offer: 'Оффер',
  country: 'Страна',
  advertiser: 'Рекламодатель',
  product: 'Продукт',
  offer_and_product: '$t(statistics:offer)/$t(statistics:product)',
  partner: 'Партнер',
  no_statistics: 'Нет статистики',
  cohort_no_statistics:
    'Для генерации когортного анализа задайте настройки когорты, параметры фильтрации, нажмите на кнопку "Применить"',
  statistics: 'Статистика',
  common: 'Общее',
  sub_id: 'Sub ID',
  custom_field: 'Custom Field',
  show_filter_by_sub_id: 'Показать фильтры по Sub ID',
  show_filter_by_custom_field: 'Показать фильтры по Custom',
  conversions: 'Конверсии',
  click_id: 'ID клика',
  clicks: 'Клики',
  unique_clicks: 'Уник. клики',
  traffic: 'Трафик',
  referer: 'Реферер клика',
  qualifications: 'Квалификации',
  conversion_id: 'ID конверсии',
  goal: 'Цель',
  events: 'События',
  event_id: 'ID события',
  event_key: 'Ключ события',
  event: 'Событие',
  idempotency_key: 'Ключ идемпотентности',
  landing: 'Лендинг',
  date: 'Дата',
  value: 'Значение',
  traffic_source: 'Источник траффика',
  traffic_source_lead_help:
    'При группировке по Sub ID 1 - Sub ID 10 можно выбрать источник траффика либо Реферальные ссылки и Промокоды, либо Внешние лиды.',
  traffic_source_click_disabled_help:
    'Чтобы выбрать Реферальную ссылку, отмените выбор Внешних лидов.',
  traffic_source_promo_disabled_help:
    'Чтобы выбрать Промокоды, отмените выбор Внешних лидов.',
  traffic_source_leads_disabled_help:
    'Чтобы выбрать Внешние лиды, отмените выбор Реферальной ссылки и Промокодов.',
  link_source: 'Источник ссылки',
  qualification_id: 'ID квалификации',
  benefit: 'Вознаграждение',
  denied: 'Отменено',
  deny: 'Отменить',
  group: 'Группировать',
  by: 'По',
  city: 'Город',
  group_by: 'Группировать по',
  group_by_add: 'Группировка',
  group_by_day: 'дням',
  group_by_hour: 'часам',
  group_by_offer: 'офферам',
  group_by_advertiser: 'рекламодателям',
  group_by_product: 'продуктам',
  group_by_partner: 'партнерам',
  group_by_geo: 'странам',
  group_by_city: 'городам',
  group_by_os: 'операционным системам',
  group_by_device: 'устройствам',
  group_by_landing: 'лендингам',
  group_by_offer_tags: 'тегам оффера',
  group_by_partner_tags: 'тегам партнера',
  group_by_promocodes: 'промокодам',
  group_by_sub_id: 'Sub ID {{subIdNumber}}',
  group_by_custom_field: 'custom{{customNumber}}',
  group_by_days_7: '7 дней',
  group_by_days_10: '10 дней',
  group_by_days_15: '15 дней',
  group_by_month: 'месяцам',
  sub_id_number: 'Sub ID {{subIdNumber}}',
  custom_field_number: 'custom{{customNumber}}',
  column_date: 'Дата',
  column_hour: 'Час',
  column_month: 'Месяц',
  column_offer: 'Оффер',
  column_count: 'Количество',
  column_advertiser: 'Рекламодатель',
  column_product: 'Продукт',
  column_partner: 'Партнер',
  column_geo: 'Страна',
  column_city: 'Город',
  column_os: 'Операционная система',
  column_device: 'Устройство',
  column_landing: 'Лендинг',
  column_tag: 'Теги',
  column_region: 'Регион',
  column_region_all: '$t(statistics:column_city)/$t(statistics:column_region)',
  group_empty: 'Не заполнено',
  group_unknown: 'Неизвестно',
  qualification_deny_confirm: 'Вы хотите отменить квалификацию?',
  incomes: 'Доход',
  payouts: 'Выплаты',
  profit: 'Прибыль',
  show_events: 'Показать события',
  show_conversions: 'Показать конверсии',
  offer_goals: 'Цели оффера',
  offer_conversions: 'Конверсии оффера {{offer}}',
  product_events: 'События продукта {{product}}',
  amount: 'Сумма',
  amount_in_dollars: 'Сумма в долларах',
  amount_in_original_currency: 'Сумма в оригинальной валюте',
  name_and_flag: 'Название и флаг',
  date_and_time: 'Дата и время',
  date_and_time_of_click: 'Дата и время клика',
  date_and_time_all:
    '$t(statistics:date_and_time)/$t(statistics:date_and_time_of_click)',
  condition_id: 'ID условия',
  its_number_in_the_database: 'Номер в базе данных',
  for_webmaster: 'Для вебмастера',
  payout_for_webmaster: 'Выплата для вебмастера',
  amount_and_currency: 'Сумма и валюта',
  tag: 'Теги',
  offer_tags: 'Теги оффера',
  partner_tags: 'Теги партнера',
  timezone: 'Часовая зона',
  cohort_analysis: 'Когортный анализ',
  cohort_settings: 'Настройки когорты',
  cohort_period: 'Период когорты',
  cohort_condition: 'Условие когорты',
  cohort_period_group: 'Группировка когорты',
  logs: 'Логи',
  grouping_cohort_day: 'По дням',
  grouping_cohort_week: 'По неделям',
  grouping_cohort_month: 'По месяцам',
  cohort_condition_clicks: 'Клики',
  common_statistics: 'Общая статистика',
  webmaster: 'Вебмастер',
  qualification: 'Квалификация',
  conversion_name: 'Наименование конверсии',
  lead_id: 'Lead ID',
  name: 'Название',
  hint: 'Подсказка',
  formula: 'Формула',
  add_custom_column: 'Добавить настраиваемое поле',
  edit_custom_column: 'Изменить настраиваемое поле',
  add_formula: 'Добавить формулу',
  edit_formula: 'Изменить формулу',
  formula_output_type_amount: 'Сумма',
  formula_output_type_average: 'Среднее',
  variables_click: 'Трафик',
  variables_event: 'События',
  variables_goal: 'Цели',
  variables_conversion: 'Конверсии',
  variables_column: 'Значение колонки',
  variables_column_disabled:
    'Столбец использует значение текущего столбца в своей формуле. Столбцы не могут ссылаться друг на друга.',
  variable_click_count: 'Хосты',
  variable_click_unique_count: 'Уникальные клики',
  variable_count: '<span class="variable-count">Кол-во</span>',
  variable_amount: '<span class="variable-amount">Сумма</span>',
  variable_count_of: '<span class="variable-count">Кол-во</span>',
  variable_amount_of: '<span class="variable-amount">Сумма</span>',
  variable_event_count: '$t(statistics:variable_count_of) событий',
  variable_event_sum: '$t(statistics:variable_amount_of) событий',
  variable_goal_count: '$t(statistics:variable_count_of) конверсий',
  variable_goal_sum: '$t(statistics:variable_amount_of) конверсий',
  variable_event_group_count: '$t(statistics:variable_count). Группы событий',
  variable_event_group_sum: '$t(statistics:variable_amount). Группы событий',
  variable_goal_group_count: '$t(statistics:variable_count). Группы целей',
  variable_goal_group_sum: '$t(statistics:variable_amount). Группы целей',
  variable_goal_group_first_date: 'Дата первой конверсии. Группы целей',
  variable_goal_group_last_date: 'Дата последней конверсии. Группы целей',
  variable_conversion_count: 'Кол-во конверсий',
  variable_conversion_income: 'Доход по конверсиям с моделью CPA',
  variable_conversion_amount: 'Выплаты по конверсиям с моделью CPA',
  variable_conversion_count_0: '$t(statistics:variable_conversion_count)',
  variable_conversion_income_0: '$t(statistics:variable_conversion_income)',
  variable_conversion_amount_0: '$t(statistics:variable_conversion_amount)',
  variable_conversion_count_1: '$t(statistics:variable_conversion_count)',
  variable_conversion_income_1: '$t(statistics:variable_conversion_income)',
  variable_conversion_amount_1: '$t(statistics:variable_conversion_amount)',
  variable_conversion_count_2: '$t(statistics:variable_conversion_count)',
  variable_conversion_income_2: '$t(statistics:variable_conversion_income)',
  variable_conversion_amount_2: '$t(statistics:variable_conversion_amount)',
  variable_conversion_count_3: '$t(statistics:variable_conversion_count)',
  variable_conversion_income_3: '$t(statistics:variable_conversion_income)',
  variable_conversion_amount_3: '$t(statistics:variable_conversion_amount)',
  variable_conversion_count_4: '$t(statistics:variable_conversion_count)',
  variable_conversion_income_4: '$t(statistics:variable_conversion_income)',
  variable_conversion_amount_4: '$t(statistics:variable_conversion_amount)',
  variable_conversion_revshare_income: 'Доход по конверсиям с моделью Ревшара',
  variable_conversion_revshare_amount:
    'Выплаты по конверсиям с моделью Ревшара',
  variable_conversion_cpm_income: 'Доход по конверсиям с моделью CPM',
  variable_conversion_cpm_amount: 'Выплаты по конверсиям с моделью CPM',
  variable_conversion_cpm_count: 'Количество действий (CPM)',
  variable_active_users_count: 'Количество активных пользователей',
  qualification_0: 'ожидание',
  qualification_1: 'выплачено',
  qualification_2: 'отклонено',
  qualification_3: 'сверх лимита',
  qualification_4: 'холд',
  add_column: 'Добавить колонку',
  column_clicks: 'Общее количество кликов',
  column_unique_clicks: 'Количество уникальных кликов',
  column_income: 'Доход',
  column_qualifications_count_payed: 'Кол-во конверсий в статусе "Confirmed"',
  column_conversions_count_payed: 'Кол-во конверсий в статусе "Confirmed"',
  column_income_payed: 'Доход по конверсиям в статусе "Confirmed"',
  column_payments_payed: 'Выплаты по конверсиям в статусе "Confirmed"',
  column_payments: 'Выплаты',
  table_settings: 'Настройки таблицы',
  default_cohort_settings_tooltip:
    'Когортный анализ не настроен. Настройте таблицу когортного анализа, добавив столбцы с требуемыми показателями.',
  clear_all: 'Очистить',
  admin_scheme: 'Таблица для администраторов',
  partner_scheme: 'Таблица для партнеров',
  reset_table_scheme: 'Восстановить настройки таблицы по умолчанию?',
  cohort_select_product: 'Для получения когортного анализа выберите продукт',
  revshare_model: 'Ревшара',
  payment_model: 'Модель оплаты',
  conversions_form_revshare_warning:
    'Среди выбранных есть конверсия <span class="font-weight-bold fw-bold">по формуле</span>. Выплата и доход по ней изменены не будут.',
  tid: 'ID транзакции',
  in_pending: 'В ожидание',
  status_description: 'Причина отклонения',
  in_hold: 'Холд',
  in_confirmed: 'Подтверждена',
  in_rejected: 'Отклонена',
  pay_out: 'Выплатить',
  partner_manager: 'Менеджер партнера',
  cancel: 'Отменить',
  browser: 'Браузер',
  os: 'ОС',
  os_version: 'Версия ОС',
  click_ip: 'IP клика',
  device_type: 'Тип устройства',
  goal_key: 'Ключ цели',
  payout_value: 'Сумма выплаты',
  payout_currency: 'Валюта выплаты',
  income_value: 'Сумма дохода',
  income_currency: 'Валюта дохода',
  note: 'Примечание',
  changes_in_data_affect_profits: 'Изменение данных влияет на прибыль',
  conversions_changed: 'Конверсии успешно изменены',
  all_conversions_changed: '{{count}} первых конверсий успешно изменено',
  select_all_conversions_1: 'Выбрать',
  select_all_conversions_2: 'конверсий',
  no_landing: 'Без лендинга',
  for: 'Для',
  for_selected_set: 'выделенных установить:',
  for_selected: 'выделенных:',
  edited_by_admin: 'Отредактировано администратором',
  custom_column_formula_placeholder:
    'Введите формулу подсчёта значения колонки, используя параметры и операции, перечисленные ниже',
  output_format: 'Формат вывода',
  output_format_type: 'Формат вывода "Итого"',
  output_format_help:
    'Вводите любой текст и добавляйте префиксы. В данном формате будет отображаться информация в статистике.',
  per_page: 'на странице',
  conversions_import_main_button: 'Изменить конверсии через CSV',
  conversions_import_title: 'Изменение конверсии через CSV',
  conversions_import_button: 'Изменить конверсии',
  conversions_import_go_back: 'Вернуться к статистике',
  conversions_import_file_accepted: 'Файл принят',
  conversions_import_file_not_processed: 'Файл не обработан',
  conversions_import_success:
    'Файл принят. Обработка займет некоторое время. Все конверсии, которые будут найдены из файла, будут изменены.',
  conversions_import_fail_1: 'В файле найдены критические ошибки. ',
  conversions_import_fail_2: 'Конверсии не могут быть изменены. ',
  conversions_import_help_1:
    'Для существующих конверсий будут применены новые параметры.',
  conversions_import_help_2: 'Новые конверсии создаваться не будут.',
  conversions_import_help_3:
    "Используйте разделители (запятая ',' или точка с запятой ';') в вашем CSV файле. Одновременно 2 разделителя в одном файле не могут быть указаны.",
  filter_promo_code: 'Промокод',
  filter_promo_code_user_id: 'Идентификатор пользователя',
  filter_fraud_risk_level: 'Уровень риска мошенничества',
  for_admins: 'Для администраторов',
  for_partners: 'Для партнёров',
  all_partners: 'все',
  table_set_as_default: 'Таблица установлена по умолчанию',
  default_for_admins: 'По умолчанию для администраторов',
  default_for_partners: 'По умолчанию для партнёров',
  set_default_for_admins: 'Установить по умолчанию для администраторов',
  set_default_for_partners: 'Установить по умолчанию для партнёров',
  table_for_admins: 'Таблица для администраторов',
  cohort_table_for_admins: 'Когортный анализ',
  table_for_partners: 'Таблица для партнеров',
  new_table_for_admins: 'Новая таблица для администраторов',
  new_table_for_partners: 'Новая таблица для партнеров',
  new_table_limit_title: 'Достигнут лимит',
  new_table_limit_text:
    'Вы можете создать максимум {{count}} таблиц для администраторов или партнеров. Удалите ненужные таблицы и создайте вместо них новые.',
  limit_placeholder: 'От 1 до 1 000 000',
  table_default_partners: 'Базовая таблица доступна всем партнерам',
  scheme_data_settings: 'Настройки',
  scheme_data_duplicate: 'Дублировать таблицу',
  scheme_remove_confirm: 'Вы хотите удалить таблицу',
  scheme_remove_default_title: 'Таблица не может быть удалена',
  scheme_remove_default_text_admins:
    'Эта таблица по умолчанию для всех администраторов. Чтобы удалить её, сначала установите другую таблицу по умолчанию.',
  scheme_remove_default_text_partners:
    'Эта таблица по умолчанию для всех партнёров. Чтобы удалить её, сначала установите другую таблицу по умолчанию.',
  status_note: 'Примечание',
  comment_to_partner: 'Комментарий партнеру',
  comment: 'Комментарий',
  to_group: 'Сгруппировать',
  group_name: 'Название группы',
  group_together: 'Сгруппировать вместе',
  add_to_group: 'Добавить в группу',
  conversions_columns_settings: 'Настроить столбцы',
  table_columns: 'Столбцы таблицы',
  deleted: 'удалено',
  customers_statistics: 'Клиенты',
  customers_goal_group: 'Группа целей',
  customers_user: 'Клиент',
  invoice_statuses: 'Статус оплаты',
  status_not_paid: 'Не оплачено',
  status_awaiting_payment: 'Ожидает оплаты',
  status_payed: 'Выплачено',
  customers_cohort_period: 'Период конверсий по выбранной группе целей',
  share: 'Поделиться',
  link_copied: 'Ссылка скопирована в буфер обмена',
  to_filter: 'Отфильтровать',
  go_to_card: 'Перейти в карточку',
  go_to_card_offer: 'Перейти в карточку оффера',
  go_to_card_partner: 'Перейти в карточку партнёра',
  set_filters: 'Установить фильтры',
};
