import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router/dist/lib/hooks';
import { Location } from '@remix-run/router';
import cloneDeep from 'lodash/cloneDeep';
import forEach from 'lodash/forEach';
import assign from 'lodash/assign';
import unset from 'lodash/unset';
import floor from 'lodash/floor';

import getObjectOnlyValidValues from 'utils/getObjectOnlyValidValues';
import { IWidget } from 'models/widget/IWidget';
import { IDataProvider } from 'models/IDataProvider';
import { IWidgetFetchOptions } from 'models/widget/IWidgetFetchOptions';
import { IMeta } from 'models/IMeta';

const getWidgetInitialState = (): IWidget => {
  return {
    widgetId: null,
    isInit: false,
    isLoading: false,
    fetchOnInit: false,
    meta: {},
    selected: [],
    error: null,
    filter: {},
  };
};

const widgets = createSlice({
  name: 'widgets',
  initialState: {} as Record<string, IWidget>,
  reducers: {
    registerWidget(
      state,
      action: PayloadAction<{
        widgetId: string;
        navigate: NavigateFunction;
        location: Location;
        options?: any;
      }>,
    ) {
      const widgetState = assign({}, getWidgetInitialState(), {
        ...action.payload,
        ...(action.payload.options ?? {}),
        isInit: true,
      }) as any;
      widgetState.navigate = undefined;
      state[action.payload.widgetId] = widgetState;
    },
    unregisterWidget(state, action: PayloadAction<string>) {
      delete state[action.payload];
    },
    handleAddInMeta(state, action: PayloadAction<string>) {
      const widgetId = action.payload;
      const widgetState: IWidget = state[widgetId] || getWidgetInitialState();
      if (widgetState.meta?.total && widgetState.meta?.per_page) {
        const newTotal = (widgetState.meta?.total || 0) + 1;
        const perPage = widgetState.meta?.per_page ?? 1;
        assign(widgetState.meta, {
          total: newTotal,
          count: (widgetState.meta?.count || 0) + 1,
          last_page:
            floor(newTotal / perPage) + (newTotal % perPage > 0 ? 1 : 0),
        });
      }
      state[widgetId] = widgetState as any;
    },
    handleDeleteInMeta(state, action: PayloadAction<string>) {
      const widgetId = action.payload;
      const widgetState: IWidget = state[widgetId] || getWidgetInitialState();
      const newTotal = (widgetState.meta?.total || 1) - 1;
      const perPage = widgetState.meta?.per_page ?? 1;
      const lastPage =
        floor(newTotal / perPage) + (newTotal % perPage > 0 ? 1 : 0);
      const currentPage = widgetState.meta?.current_page || 1;
      assign(widgetState.meta, {
        total: newTotal,
        count: (widgetState.meta?.count || 1) - 1,
        last_page: lastPage,
        current_page: currentPage > lastPage ? lastPage : currentPage,
      });
      state[widgetId] = widgetState as any;
    },
    widgetRequest(
      state,
      action: PayloadAction<{
        widgetId: string;
        dataProvider: IDataProvider;
        navigate: NavigateFunction;
        location: Location;
        options: IWidgetFetchOptions;
      }>,
    ) {},
    setError(
      state,
      action: PayloadAction<{
        widgetId: string;
        error: any;
      }>,
    ) {
      const widgetId = action.payload.widgetId;
      const widgetState: IWidget = state[widgetId] || getWidgetInitialState();
      widgetState.error = action.payload.error;
      state[widgetId] = widgetState as any;
    },
    setLoading(
      state,
      action: PayloadAction<{
        widgetId: string;
        isLoading: boolean;
      }>,
    ) {
      const widgetId = action.payload.widgetId;
      const widgetState: IWidget = state[widgetId] || getWidgetInitialState();
      widgetState.isLoading = action.payload.isLoading;
      state[widgetId] = widgetState as any;
    },
    setMeta(
      state,
      action: PayloadAction<{
        widgetId: string;
        meta: IMeta;
      }>,
    ) {
      const widgetId = action.payload.widgetId;
      const widgetState: IWidget = state[widgetId] || getWidgetInitialState();
      widgetState.meta = action.payload.meta;
      state[widgetId] = widgetState as any;
    },
    setFilters(
      state,
      action: PayloadAction<{
        widgetId: string;
        filter: Record<string, any>;
        merge?: boolean;
        removeUndefined?: boolean;
        partialUpdate?: boolean;
      }>,
    ) {
      const widgetId = action.payload.widgetId;
      const widgetState: IWidget = state[widgetId] || getWidgetInitialState();

      const partialUpdate = action.payload.partialUpdate ?? false;
      const removeUndefined = action.payload.removeUndefined ?? true;
      const actionNewInnerFilter = assign(
        {},
        widgetState.filter?.values?.filter,
        getObjectOnlyValidValues(
          action.payload.filter?.filter,
          removeUndefined && partialUpdate,
        ),
      );
      const actionValidNewInnerFilter = getObjectOnlyValidValues(
        actionNewInnerFilter,
        removeUndefined,
      );
      const newFilter = assign(
        {},
        widgetState.filter?.values,
        getObjectOnlyValidValues(
          action.payload.filter,
          removeUndefined && partialUpdate,
        ),
        {
          filter: actionValidNewInnerFilter,
        },
      );
      const newValidFilter = getObjectOnlyValidValues(
        newFilter,
        removeUndefined,
      );
      widgetState.filter = assign({}, widgetState.filter, {
        values: action.payload.merge ? newValidFilter : action.payload.filter,
      });

      state[widgetId] = widgetState as any;
    },
    removeFilterParams(
      state,
      action: PayloadAction<{
        widgetId: string;
        params: string[];
      }>,
    ) {
      const widgetId = action.payload.widgetId;
      const widgetState: IWidget = state[widgetId] || getWidgetInitialState();

      const values = cloneDeep(widgetState.filter?.values);
      forEach(action.payload.params, (param: string) => unset(values, param));
      widgetState.filter = assign({}, widgetState.filter, {
        widgetId: widgetState.filter?.widgetId ?? undefined,
        values,
      });

      state[widgetId] = widgetState as any;
    },
    setFilterMappingFromUrlDone(
      state,
      action: PayloadAction<{
        widgetId: string;
        isDone: boolean;
      }>,
    ) {
      const widgetId = action.payload.widgetId;
      const widgetState: IWidget = state[widgetId] || getWidgetInitialState();

      widgetState.filter = assign({}, widgetState.filter, {
        widgetId: widgetState.filter?.widgetId ?? undefined,
        mappingFromUrlDone: action.payload.isDone,
      });

      state[widgetId] = widgetState as any;
    },
    filterMapToURL(
      state,
      action: PayloadAction<{
        widgetId: string;
        navigate: NavigateFunction;
        location: Location;
      }>,
    ) {},
  },
});

export const { actions, reducer } = widgets;
export const {
  filterMapToURL,
  setFilterMappingFromUrlDone,
  setFilters,
  handleDeleteInMeta,
  handleAddInMeta,
  removeFilterParams,
  setError,
  setMeta,
  setLoading,
  unregisterWidget,
  widgetRequest,
  registerWidget,
} = actions;
export default reducer;
