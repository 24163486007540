import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICurrency } from 'models/ICurrency';

interface CurrencyState {
  currencies: ICurrency[];
  sortedCurrencies: ICurrency[];
  defaultCurrency: string | null;
  currencyStatus: Record<
    string,
    { value: boolean | undefined; isLoading: boolean }
  >;
}

const initialState: CurrencyState = {
  currencies: [],
  sortedCurrencies: [],
  defaultCurrency: null,
  currencyStatus: {},
};

const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    setCurrencies(state, action: PayloadAction<ICurrency[]>) {
      state.currencies = action.payload;
      state.sortedCurrencies = sortCurrencies(
        state.currencies,
        state.defaultCurrency,
      );
      state.currencyStatus = transformCurrencyArray(state.currencies);
    },
    setCurrencyStatus(state, action: PayloadAction<ICurrency>) {
      state.currencies = state.currencies.map((c) =>
        c.currency_code === action.payload.currency_code ? action.payload : c,
      );
      state.sortedCurrencies = sortCurrencies(
        state.currencies,
        state.defaultCurrency,
      );
      state.currencyStatus[action.payload.currency_code].value =
        action.payload.is_enable;
    },
    setCurrencyStatusLoading(
      state,
      action: PayloadAction<{ currency_code: string; isLoading: boolean }>,
    ) {
      const currentStatus = state.currencyStatus[action.payload.currency_code];
      state.currencyStatus[action.payload.currency_code] = {
        ...currentStatus,
        isLoading: action.payload.isLoading,
      };
    },
    addCustomCurrency(state, action: PayloadAction<ICurrency>) {
      state.currencies = [action.payload, ...state.currencies];

      state.sortedCurrencies = sortCurrencies(
        state.currencies,
        state.defaultCurrency,
      );

      state.currencyStatus = {
        ...state.currencyStatus,
        [action.payload.currency_code]: {
          value: action.payload.is_enable,
          isLoading: false,
        },
      };
    },
    editCustomCurrency(state, action: PayloadAction<ICurrency>) {
      const { currency_code, rate } = action.payload;

      const index = state.currencies.findIndex(
        (currency) => currency.currency_code === currency_code,
      );

      if (index !== -1) {
        state.currencies[index].rate = rate;

        state.sortedCurrencies = sortCurrencies(
          state.currencies,
          state.defaultCurrency,
        );
      }
    },
  },
});

function transformCurrencyArray(currencyArray: ICurrency[]) {
  return currencyArray.reduce((acc, currency) => {
    if (currency.currency_code) {
      acc[currency.currency_code] = {
        value: currency?.is_enable,
        isLoading: false,
      };
    }
    return acc;
  }, {} as Record<string, { value: boolean | undefined; isLoading: boolean }>);
}

const sortCurrencies = (
  currencies: ICurrency[],
  defaultCurrency: string | null,
): ICurrency[] => {
  if (!defaultCurrency) return currencies;

  return currencies.slice().sort((a, b) => {
    if (a.currency_code === defaultCurrency) return -1;
    if (b.currency_code === defaultCurrency) return 1;

    if (a.is_enable || b.is_enable) return 1;

    if (a.is_custom !== b.is_custom) {
      return a.is_custom ? -1 : 1;
    }

    if (a.currency_code < b.currency_code) return -1;
    if (a.currency_code > b.currency_code) return 1;

    return 0;
  });
};

export const { actions, reducer } = currencySlice;
export const {
  setCurrencies,
  setCurrencyStatus,
  setCurrencyStatusLoading,
  addCustomCurrency,
  editCustomCurrency,
} = actions;

export default reducer;
