import { Middleware } from 'redux';
import { configureStore as configureReduxStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { batchDispatchMiddleware } from 'redux-batched-actions';

import rootSaga from './sagas';
import widgets from './slices/widgets';
import models from './slices/models';
import notifications from './slices/notifications';
import dicts from './slices/dicts';
import globals from './slices/globals';
import offerPartners from './slices/offerPartners';
import landings from './slices/landings';
import modals from './slices/modals';
import tables from './slices/tables';
import layout from './slices/layout';
import currency from './slices/currency';

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(initialState = {}) {
  const middleware: Array<Middleware> = [
    batchDispatchMiddleware,
    sagaMiddleware,
  ];

  const store = configureReduxStore({
    reducer: {
      widgets,
      models,
      notifications,
      dicts,
      globals,
      offerPartners,
      landings,
      modals,
      tables,
      layout,
      currency,
    } as any,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        serializableCheck: false,
      }).concat(middleware);
    },
  });
  sagaMiddleware.run(rootSaga);

  return store;
}
