export default {
  'edit profile': 'Editar perfil',
  'change password': 'Cambiar contraseña',
  created: 'Creado',
  active: 'Activo',
  banned: 'Prohibido',
  incomplete_registration: 'Inscripción incompleta',
  on_moderation: 'En la moderación',
  rejected: 'Rechazado',
  disabled: 'Desactivado',
  enabled: 'Activo',
  activate: 'Activar',
  reject: 'Rechazar',
  account_is_not_confirmed_yet: 'La cuenta aún no ha sido verificada. ',
  to_activate_the_user: '¿Quieres activar este usuario?',
  postback_settings: 'Preferencias de Postbacks',
  event_postback_settings_label: 'Enlace para enviar un postback con eventos',
  event_postback_settings_help:
    'URL para enviar postbacks con eventos. Puede copiar el enlace para usar en sus productos.',
  goal_postback_settings_label: 'Enlace para enviar un postback con objetivos',
  goal_postback_settings_help:
    'URL para enviar postbacks con objetivos. Puede copiar el enlace para usar en sus productos.',
  relation_postback_settings_help:
    'URL para enviar postbacks de enlace. Puede copiar el enlace para usar en sus productos.',
  profile_approval_has_been_rejected: 'Usuario rechazado con éxito',
  profile_approval_confirm: '¿Desea activar el usuario?',
  profile_reject_confirm: '¿Desea rechazar a un usuario?',
  manager: 'Gerente',
  contacts: 'Contactos',
  manager_contacts: 'Contactos del Gerente',
  client_key: 'Clave de cliente',
  your_client_key: 'Su clave de cliente',
  generate_new_client_key: 'Generar una nueva clave de cliente',
  client_key_help:
    'La clave de cliente es utilizada por el sistema de afiliados para identificar al usuario cuando recibe un postback. Puede copiar la clave de cliente. También puede crear uno nuevo.',
  send_follow_up_email: 'Reenviar correo electrónico',
  email_has_been_sent: 'Carta de confirmación enviada',
  client_key_generate_confirm:
    '¿Está seguro de que desea actualizar su clave de cliente? Después de la actualización, los postbacks con la clave antigua dejarán de ser aceptados.',
  partner_invite_link: 'Enlace para invitación de socios',
  api_key: 'Clave API',
  api_key_update_title: 'Generar una nueva clave',
  api_key_update_confirm:
    '¿Realmente desea generar una nueva clave API? Las solicitudes con la clave antigua dejarán de funcionar.',
  api_key_updated: 'Clave API actualizada con éxito',
  tags: 'Etiquetas',
  source_of_registration: 'Fuente de registro',
  secret_key: 'la clave secreta',
  secret_key_help:
    'La clave secreta se utiliza para verificar la integridad de los datos transmitidos al enviar y recibir devolución de datos.',
  generate_new_secret_key: 'Generar una nueva clave secreta',
  secret_key_generate_confirm:
    '¿Está seguro de que desea actualizar su clave secreta? Después de la actualización, los postbacks con la clave antigua dejarán de ser aceptados.',
};
