export default {
  no_data: 'No export results',
  column_date: 'Date',
  column_type: 'Type',
  column_status: 'Status',
  description_1st_line: 'File preparation usually takes 1-3 minutes',
  description_2nd_line_1st_part:
    'If the document status has not changed after 3 minutes,',
  description_2nd_line_2nd_part: 'refresh the page',
  description_3rd_line: 'Ready files are stored for 1 day',
  type_conversions: 'Conversions',
  type_common_statistic_snapshot: 'Common statistics',
  type_invoices: 'Invoice',
  type_partners: 'Partners',
  type_advertisers: 'Advertisers',
  type_admins: 'Admins',
  type_payouts: 'Payouts',
  type_promos: 'Promos',
  type_offers: 'Offers',
  type_products: 'Products',
  type_events: 'Events',
  type_cohort_clicks: 'Cohort Clicks',
  type_cohort_events: 'Cohort Events',
  type_cohort_conversions: 'Cohort Conversions',
};
