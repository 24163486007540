export enum Color {
  PRIMARY = 'primary',
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  DANGER = 'danger',
  SECONDARY = 'secondary',
  TRANSPARENT = 'transparent',
  FIELD = 'field',
  AZURE = 'azure',
  LINK = 'link',
  CARROT = 'carrot',
  LIGHT_PRIMARY = 'light-primary',
  LIGHT_SUCCESS = 'light-success',
  LIGHT_SECONDARY = 'light-secondary',
  LIGHT_WARNING = 'light-warning',
  LIGHT_CARROT = 'light-carrot',
  LIGHT_DANGER = 'light-danger',
  LIGHT_AZURE = 'light-azure',
  LIGHT_INFO = 'light-info',
  LIGHT_DARK = 'light-dark',
  LIGHT_VIOLET = 'light-violet',
  MUTED = 'muted',
  DARK = 'dark',
  LIGHT = 'light',
  GRAY = 'gray',
  ORANGE = 'orange',
  YELLOW = 'yellow',
}
