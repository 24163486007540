import React from 'react';
import { createRoot } from 'react-dom/client';
import './i18n';

import App from './App';
// import * as serviceWorker from './serviceWorker';
import './assets/sass/main.scss';

disableDoubleClick();

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// Workaround for https://bugs.webkit.org/show_bug.cgi?id=216681.
function disableDoubleClick() {
  let oldAddEventListener = Node.prototype.addEventListener;
  Node.prototype.addEventListener = function (type) {
    if (type === 'dblclick') {
      // ignore
      return;
    }
    return oldAddEventListener.apply(this, arguments as any);
  };
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
